import MoreVertIcon from "@mui/icons-material/MoreVert"
import { Menu, MenuItem, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import IconButton from "@mui/material/IconButton"
import TextField from "@mui/material/TextField"
import { useAuthUser } from "features/auth/hooks"
import * as React from "react"
import { ChangeEvent, useCallback, useMemo, useState } from "react"
import { useDispatch } from "react-redux"
import { api } from "services/api"
import {
  useChangeStrategyMutation,
  useCloneStrategyMutation,
  useDeleteStrategyMutation,
} from "services/rangesApi"
import { RangeSet } from "services/types"
import { RangeParameters } from "./types"

export interface RangeToolbarProps {
  parameters: RangeParameters
  strategies: RangeSet[]
}

export function ManageStrategiesToolbar(props: RangeToolbarProps) {
  const { parameters, strategies } = props
  const user = useAuthUser()
  const [removeStrategy, { data, isLoading, error }] =
    useDeleteStrategyMutation()
  const [cloneStrategy, { data: cloneResult }] = useCloneStrategyMutation()
  const [changeStrategy, { data: changeResult }] = useChangeStrategyMutation()

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const [rename, showRenameDialog] = useState(false)
  const [clone, showCloneDialog] = useState(false)
  const [name, setName] = React.useState("")
  const dispatch = useDispatch()
  const handleImport = useCallback(
    (event: ChangeEvent) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file == null) {
        return
      }
      const url = `api/preflop/rangeset/import`
      const formData = new FormData()
      formData.append("file", file)
      formData.append("fileName", file.name)
      formData.append("name", file.name)
      fetch(url, { body: formData, method: "POST" }).then(() => {
        dispatch(api.util.invalidateTags(["RangeStretegies"]))
      })
    },
    [dispatch]
  )

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleDelete = () => {
    parameters.rangeset && removeStrategy(parameters.rangeset)
  }

  const closeDialog = () => {
    rename && showRenameDialog(false)
    clone && showCloneDialog(false)
  }

  const handleOk = () => {
    parameters.rangeset &&
      clone &&
      cloneStrategy({
        id: parameters.rangeset,
        name: name,
      })
    parameters.rangeset &&
      rename &&
      changeStrategy({
        id: parameters.rangeset,
        name: name,
      })
    closeDialog()
  }

  const handleRename = () => {
    showRenameDialog(true)
  }
  const handleClone = () => {
    showCloneDialog(true)
  }

  const strategyInfo = useMemo(() => {
    const rv = new Map<string, RangeSet>()
    strategies.forEach((v) => {
      rv.set(v.id, v)
    })
    return rv
  }, [strategies])

  // if (!user?.systemAdmin) return null

  return (
    <Box sx={{ display: "flex", pb: 1, alignItems: "center" }}>
      <Typography variant="body1" sx={{ pr: 1 }}>
        RangeSet - {strategyInfo.get(parameters.rangeset || "")?.name}
      </Typography>
      {parameters.rangeset && (
        <IconButton
          size="small"
          aria-label="delete"
          color="primary"
          onClick={handleMenu}
          sx={{
            color: "#777777",
            "&.MuiIconButton-root": {
              border: "1px solid #999999",
              padding: 0,
            },
          }}
        >
          <MoreVertIcon />
        </IconButton>
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={handleRename}>Rename</MenuItem>
        {/* <MenuItem onClick={handleRename}>Edit</MenuItem> */}
        <MenuItem onClick={handleClone}>Clone</MenuItem>
        <MenuItem
          component="a"
          href={`/api/preflop/rangeset/${parameters.rangeset}/export`}
        >
          Export
        </MenuItem>
        <MenuItem>
          <input
            style={{ display: "none" }}
            id="import-file"
            multiple
            type="file"
            onChange={handleImport}
          />
          <label htmlFor="import-file">Import</label>
        </MenuItem>
        <MenuItem onClick={handleDelete}>Delete</MenuItem>
      </Menu>
      <Dialog open={rename || clone} onClose={handleClose}>
        <DialogTitle>
          {rename && <span>Rename RangeSet</span>}
          {clone && <span>Clone RangeSet</span>}
        </DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Name"
            type="name"
            fullWidth
            variant="standard"
            value={name}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
