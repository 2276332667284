import ClearIcon from "@mui/icons-material/Clear"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import ContentPasteIcon from "@mui/icons-material/ContentPaste"
import SaveIcon from "@mui/icons-material/Save"
import { IconButton } from "@mui/material"
import MuiAlert, { AlertColor, AlertProps } from "@mui/material/Alert"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Snackbar from "@mui/material/Snackbar"
import TextField from "@mui/material/TextField"
import { forwardRef, useCallback, useEffect, useMemo, useState } from "react"
import { useRangeMutation, useSaveRangeMutation } from "services/rangesApi"
import { RangeInfo, SaveRangeRequest } from "services/types"
import { CardInfoEdit } from "./CardInfoEdit"
import { CardInfo } from "./Ranges"
import {
  Action,
  ActionRatios,
  ACTION_COLORS,
  ACTION_NAMES,
  Cards,
  CATEGORY_RFI,
  RangeParameters,
} from "./types"
import {
  checkRatio,
  getAvailableActions,
  getCellBackground,
  getCellId,
  toPercent,
  validMonkerCombo,
  validPIOCombo,
} from "./utils"

interface RangeAction {
  action: Action
  percent?: number
  combinations?: number
}

export interface RangeEditProps {
  parameters: RangeParameters
}

const Alert = forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export function RangeEdit(props: RangeEditProps) {
  const { parameters } = props

  const [filterAction, setActionFilter] = useState<
    Action | "CUSTOM" | undefined
  >(undefined)
  const [currentCard, setCurrentCard] = useState<CardInfo | undefined>(
    undefined
  )
  const [getRange, { data: rdata, isLoading, error }] = useRangeMutation()
  const [
    saveRange,
    { data: saveData, isLoading: saveLoading, error: saveError },
  ] = useSaveRangeMutation()
  const [manualRatioError, setManualRatioError] = useState<string | undefined>()

  const [betsize, setBetSize] = useState<string>("")
  const [range, setRange] = useState<RangeInfo>()
  const [customRatio, setCustomRatio] = useState<ActionRatios>({
    ALL_IN: 0.25,
    CALL: 0.25,
    FOLD: 0.25,
    RAISE: 0.25,
  })
  const [notification, setNotification] = useState<
    | undefined
    | {
        message: string
        severity: AlertColor
      }
  >()

  const hideNotification = () => {
    setNotification(undefined)
  }

  const changeBetSize = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBetSize(event.target.value)
  }

  const resetActionRatio = () => {
    setCustomRatio({
      ALL_IN: 0,
      CALL: 0,
      FOLD: 0,
      RAISE: 0,
    })
  }

  const applyToAllCombos = () => {
    if (!customRatio) return
    if (range === undefined) return

    const newRange: Record<string, Record<string, number>> = {}
    for (let i = 0; i < Cards.length; i++) {
      for (let j = 0; j < Cards.length; j++) {
        const cellId: string = getCellId(i, j)
        newRange[cellId] = customRatio
      }
    }
    const newVal: RangeInfo = {
      raiseSizing: range.raiseSizing,
      ranges: newRange,
    }
    setRange(newVal)
  }

  const setActionRatio = (action: Action, percent: number) => {
    const newVal = Object.assign({}, customRatio, {
      [action]: (percent / 100).toFixed(4),
    })
    let totalPercents = 0.0
    getAvailableActions().forEach((action) => {
      totalPercents += newVal[action] || 0
    })
    if (totalPercents > 100) {
      setManualRatioError("Sum of all actions should not be more than 100%")
    } else {
      setManualRatioError(undefined)
    }
    setCustomRatio(newVal)
  }

  const setAllInRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("ALL_IN", Number(event.target.value))
  }

  const setRaiseRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("RAISE", Number(event.target.value))
  }

  const setFoldRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("FOLD", Number(event.target.value))
  }

  const setCallRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("CALL", Number(event.target.value))
  }

  useEffect(() => {
    getRange({
      rangeset_id: parameters.rangeset || "",
      stack_size: Number(parameters.stackSize) || 0,
      position: parameters.heroPosition || "",
      category: parameters.category || "",
    })
    setCurrentCard(undefined)
  }, [getRange, parameters])

  useEffect(() => {
    if (rdata !== undefined) {
      setRange(rdata)
    }
  }, [rdata])

  const selectCell = useCallback(
    (card: CardInfo | undefined) => {
      setCurrentCard(card)
      if (card) {
        const { valid } = checkRatio(card.ratio || {})
        if (valid) setCustomRatio(card.ratio || {})
      }
    },
    [setCurrentCard]
  )

  const updateCell = useCallback(
    (cardInfo: CardInfo) => {
      if (range === undefined) return

      const newRange: Record<string, Record<string, number>> = {}
      let cardFound = false
      for (const [card, value] of Object.entries(range.ranges)) {
        if (card == cardInfo.cardId && cardInfo.ratio !== undefined) {
          newRange[card] = cardInfo.ratio
          cardFound = true
        } else newRange[card] = value
      }
      if (!cardFound) newRange[cardInfo.cardId] = cardInfo.ratio || {}

      const newVal: RangeInfo = {
        raiseSizing: range.raiseSizing,
        ranges: newRange,
      }
      setRange(newVal)

      if (currentCard?.cardId == cardInfo.cardId) {
        setCurrentCard({
          cardId: cardInfo.cardId,
          ratio: cardInfo?.ratio,
        })
      }
    },
    [range, setRange, setCurrentCard, currentCard?.cardId]
  )

  const importActions = useCallback(
    (cardsInfo: CardInfo[]) => {
      if (range === undefined) return

      const newRange: Record<string, Record<string, number>> = {}

      // iterate over updated combos
      for (const cardInfo of cardsInfo) {
        let cardFound = false
        for (const [card, value] of Object.entries(range.ranges)) {
          if (card == cardInfo.cardId && cardInfo.ratio !== undefined) {
            const newCombo = Object.assign({}, value, cardInfo.ratio)

            // console.log("Was", card, value, " => ", newCombo)
            newRange[card] = newCombo
            cardFound = true
          } //else newRange[card] = value
        }
        if (!cardFound) newRange[cardInfo.cardId] = cardInfo.ratio || {}
      }

      // iterate over existing range to keep not updates combos
      for (const rangeCombo in range.ranges) {
        let cardFound = false
        for (const updateInfo of cardsInfo) {
          if (updateInfo.cardId == rangeCombo) {
            cardFound = true
            continue
          }
        }
        if (!cardFound) newRange[rangeCombo] = range.ranges[rangeCombo]
      }

      // recalculate fold to be default action if import non fold action
      for (let i = 0; i < Cards.length; i++) {
        for (let j = 0; j < Cards.length; j++) {
          const cellId: string = getCellId(i, j)
          const ratio = newRange[cellId] || {}
          let total = 0
          for (const action in ratio) {
            total += action == "FOLD" ? 0 : ratio[action] || 0
          }
          const newRatio = Object.assign({}, ratio, { FOLD: 1 - total })
          newRange[cellId] = newRatio
        }
      }

      const newVal: RangeInfo = {
        raiseSizing: range.raiseSizing,
        ranges: newRange,
      }
      console.log("setRange", newVal)
      setRange(newVal)
    },
    [range, setRange]
  )

  const handleSave = () => {
    const saveRequest: SaveRangeRequest = {
      rangeset_id: parameters.rangeset || "",
      category: CATEGORY_RFI,
      stack_size: Number(parameters.stackSize) || 0,
      position: parameters.heroPosition || "",
      range: {
        raiseSizing: Number(betsize),
        ranges: range?.ranges || {},
      },
    }
    saveRange(saveRequest)
  }

  // Monker parser - exact cards as keys, need to merge to combos
  /*
  As4s:0.359,
  As5s:0.9999,
  As6s:0.9999,
  As7s:0.9999,
  As8s:0.9999,
  As9s:0.9999,
  AsTs:0.9997,
  AsJs:0.9999,
  AsQs:0.9999,
  ...
  */
  // PIO format parser - combos as keys
  /*
  AA,KK,QQ:0.9999,JJ:0.9999,...,43s:0.1979
  */
  const handleImport = () => {
    navigator.clipboard.readText().then((clipText) => {
      if (
        filterAction === undefined ||
        filterAction === "CUSTOM" ||
        filterAction == "FOLD"
      ) {
        setNotification({
          message:
            "Please select valid action for import. Fold is not applicable",
          severity: "error",
        })
        return
      }
      if (clipText) {
        const cards = clipText.split(",").map((v) => v.trim())
        const updates: CardInfo[] = []

        const { valid: monkerFormat } = validMonkerCombo(
          cards[0] ? cards[0].split(":")[0] : ""
        )
        console.log(`Monker ${monkerFormat}`)
        // auto-detect input format - try Monker First
        for (const card of cards) {
          const [combo, percent] = card.split(":")

          const { valid, combos } = monkerFormat
            ? validMonkerCombo(combo)
            : validPIOCombo(combo)
          if (!valid) {
            console.log(
              `Wrong ${monkerFormat ? "Monker" : "PIO"} combo: ${card}`
            )
            continue
          }
          if (!combos || !(combos?.length > 0)) {
            console.log(
              `Wrong ${monkerFormat ? "Monker" : "PIO"} combo: ${card}`
            )
            continue
          }
          // console.log("Card", card, "combo", combo, percent)
          for (const wlpCombo of combos) {
            const cardInfo: CardInfo = {
              cardId: wlpCombo,
              ratio: {
                [filterAction as Action]: Number(percent) || 1,
              },
            }
            updates.push(cardInfo)
          }
        }

        if (updates.length > 0) {
          // console.log("PPP", updates)
          importActions(updates)
          // console.log("ZZZ", updates)
          setNotification({
            message: `Set from clipboard - format ${
              monkerFormat ? "Monker" : "PIO"
            }`,
            severity: "success",
          })
        } else {
          setNotification({
            message: "No updates in PIO or Monker format found in clipboard",
            severity: "warning",
          })
        }
      }
    })
  }

  const handleExport = () => {
    if (range === undefined) return
    if (filterAction === undefined || filterAction === "CUSTOM") {
      setNotification({
        message: "Please select action to export",
        severity: "error",
      })
      return
    }

    const toExport: string[] = []
    for (const card in range.ranges) {
      const ratio = range.ranges[card]
      const actionRatio = ratio[filterAction]
      if (actionRatio > 0) {
        console.log(card)
        toExport.push(actionRatio < 1 ? `${card}:${actionRatio}` : card)
      }
    }
    navigator.clipboard.writeText(toExport.join(","))
    setNotification({
      message: "Copied to clipboard",
      severity: "success",
    })
  }

  const rows = useMemo(() => {
    if (!range) {
      return []
    }
    setBetSize(range.raiseSizing.toString())
    const rows = []
    for (let i = 0; i < Cards.length; i++) {
      const cells = []
      for (let j = 0; j < Cards.length; j++) {
        const cellId: string = getCellId(i, j)
        const decimalRatio = range.ranges[cellId]
        const ratio: Record<string, number> = {}
        for (const op in decimalRatio) {
          ratio[op] = decimalRatio[op]
        }
        const { valid } = checkRatio(ratio)
        const cell = (
          <Box
            sx={{
              padding: { xs: "1px", sm: "2px", md: "4px" },
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
              background: valid
                ? getCellBackground(ratio, undefined, false)
                : "none",
            }}
            onClick={() => {
              let newRatio = ratio
              if (filterAction !== undefined) {
                if (filterAction !== "CUSTOM") {
                  newRatio = { [filterAction]: 1 }
                  updateCell({ cardId: cellId, ratio: { [filterAction]: 1 } })
                } else {
                  newRatio = customRatio
                  updateCell({
                    cardId: cellId,
                    ratio: {
                      ALL_IN: customRatio.ALL_IN || 0,
                      RAISE: customRatio.RAISE || 0,
                      FOLD: customRatio.FOLD || 0,
                      CALL: customRatio.CALL || 0,
                    },
                  })
                }
              }
              selectCell({ cardId: cellId, ratio: newRatio })
            }}
            key={cellId}
            className={`range-cell ${valid ? "" : "outofrange"}`}
          >
            <Box>{cellId}</Box>
          </Box>
        )
        cells.push(cell)
      }
      const row = (
        <div key={i} className="range-row">
          {cells}
        </div>
      )
      rows.push(row)
    }
    return rows
  }, [filterAction, selectCell, updateCell, customRatio, range])

  const actions: RangeAction[] = useMemo(() => {
    const definedActions: Action[] = getAvailableActions()
    const rv: RangeAction[] = definedActions.map((action): RangeAction => {
      return {
        action: action,
        combinations: undefined,
      }
    })
    return rv
  }, [])

  return (
    <Box
      sx={{
        flexGrow: 1,
        minWidth: "700px",
        p: 2,
        borderRadius: "12px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#EEEEEE",
      }}
    >
      {" "}
      {notification !== undefined && (
        <Snackbar
          open={notification !== undefined}
          autoHideDuration={6000}
          onClose={hideNotification}
        >
          <Alert
            onClose={hideNotification}
            severity={notification.severity}
            sx={{ width: "100%" }}
          >
            {notification.message}
          </Alert>
        </Snackbar>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ fontWeight: "bold" }}>
              {parameters.stackSize}bb {parameters.heroPosition}{" "}
              {parameters.category}
            </Box>
            <Box
              sx={{
                pl: 1,
                fontSize: "12px",
                display: "flex",
                flexWrap: "nowrap",
                alignItems: "center",
                flexGrow: 1,
              }}
            >
              <Box sx={{ pr: 1 }}>Raise sizing:</Box>
              <TextField
                autoFocus
                size="small"
                margin="dense"
                id="betsize"
                type="number"
                variant="standard"
                value={betsize}
                onChange={changeBetSize}
                sx={{
                  "&.MuiInput-root .MuiInputBase-root": {
                    fontSize: "10px",
                    width: "50px",
                  },
                }}
              />
              xx
            </Box>
            <Box>
              <Button
                variant="contained"
                endIcon={<SaveIcon />}
                sx={{
                  "&.MuiButton-root": {
                    fontSize: "10px",
                    width: "50px",
                    background: "#444444",
                    textTransform: "none",
                  },
                }}
                onClick={handleSave}
              >
                Save
              </Button>
              <Button
                variant="contained"
                endIcon={<ContentCopyIcon />}
                sx={{
                  "&.MuiButton-root": {
                    ml: 1,
                    fontSize: "10px",
                    width: "50px",
                    background: "#444444",
                    textTransform: "none",
                  },
                }}
                onClick={handleExport}
              >
                Export
              </Button>
              <Button
                variant="contained"
                endIcon={<ContentPasteIcon />}
                sx={{
                  "&.MuiButton-root": {
                    ml: 1,
                    fontSize: "10px",
                    width: "50px",
                    background: "#444444",
                    textTransform: "none",
                  },
                }}
                onClick={handleImport}
              >
                Import
              </Button>
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", pt: 1 }}>
            {actions.map((op) => (
              <Box key={op.action} sx={{ mr: "10px" }}>
                <Button
                  size="small"
                  sx={{
                    mt: "4px",
                    textTransform: "none",
                    "&.MuiButton-root": {
                      minWidth: "100px",
                      minHeight: "20px",
                      borderRadius: "8px",
                      justifyContent: "flex-start",
                    },
                    "&.MuiButton-root.MuiButton-contained": {
                      backgroundColor: "darkcyan",
                    },
                  }}
                  variant={
                    filterAction !== op.action ? "outlined" : "contained"
                  }
                  onClick={() =>
                    setActionFilter(
                      op.action !== filterAction ? op.action : undefined
                    )
                  }
                >
                  <Box
                    sx={{
                      minWidth: "14px",
                      minHeight: "14px",
                      border: "1px solid #333333",
                      borderRadius: "50%",
                      background: ACTION_COLORS[op.action],
                    }}
                  ></Box>
                  <Box sx={{ pl: "4px", flexWrap: "nowrap", display: "flex" }}>
                    {ACTION_NAMES[op.action]}
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              mt: 1,
              p: 1,
              alignItems: "center",
              border: "1px solid #CCCCCC",
              borderRadius: "12px",
            }}
          >
            <Button
              size="small"
              sx={{
                mt: "4px",
                textTransform: "none",
                "&.MuiButton-root": {
                  minWidth: "100px",
                  minHeight: "30px",
                  borderRadius: "8px",
                  justifyContent: "flex-start",
                },
                "&.MuiButton-root.MuiButton-contained": {
                  backgroundColor: "darkcyan",
                },
              }}
              variant={filterAction !== "CUSTOM" ? "outlined" : "contained"}
              onClick={() =>
                setActionFilter(
                  "CUSTOM" !== filterAction ? "CUSTOM" : undefined
                )
              }
            >
              Manual
            </Button>
            <Box key={"CUSTOM"} sx={{ mr: "10px", pl: "10px" }}>
              <Button
                size="small"
                sx={{
                  mt: "4px",
                  textTransform: "none",
                  "&.MuiButton-root": {
                    minWidth: "100px",
                    minHeight: "30px",
                    borderRadius: "8px",
                    justifyContent: "flex-start",
                    background: getCellBackground(customRatio, undefined, true),
                  },
                  "&.MuiButton-root.MuiButton-contained": {},
                }}
                variant={"contained"}
              ></Button>
            </Box>
            <Box>
              <Box sx={{ display: "flex" }}>
                <Box sx={{ width: "50px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    type="number"
                    variant="standard"
                    value={toPercent(customRatio.ALL_IN || 0)}
                    onChange={setAllInRatio}
                    sx={{
                      pr: 1,
                      "& .MuiInput-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "50px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    type="number"
                    variant="standard"
                    value={toPercent(customRatio.RAISE || 0)}
                    onChange={setRaiseRatio}
                    sx={{
                      pr: 1,
                      "& .MuiInput-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "50px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    type="number"
                    variant="standard"
                    value={toPercent(customRatio.CALL || 0)}
                    onChange={setCallRatio}
                    sx={{
                      pr: 1,
                      "& .MuiInput-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Box>
                <Box sx={{ width: "50px" }}>
                  <TextField
                    autoFocus
                    margin="dense"
                    type="number"
                    variant="standard"
                    value={toPercent(customRatio.FOLD || 0)}
                    onChange={setFoldRatio}
                    sx={{
                      pr: 1,
                      "& .MuiInput-input": {
                        fontSize: "14px",
                      },
                    }}
                  />
                </Box>
                <IconButton
                  onClick={() => {
                    resetActionRatio()
                  }}
                >
                  <ClearIcon />
                </IconButton>
                <Button onClick={applyToAllCombos}>Apply to all</Button>
              </Box>
              <Box>
                {manualRatioError && (
                  <Box sx={{ color: "red" }}>{manualRatioError}</Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: 2 }}>{rows}</Box>
      <Box sx={{ pt: 2 }}></Box>
      {currentCard && (
        <CardInfoEdit card={currentCard} updateCallback={updateCell} />
      )}
    </Box>
  )
}
