import { createTheme } from "@mui/material/styles"
// import useMediaQuery from "@mui/material/useMediaQuery"
import { useMemo } from "react"

export function useTheme() {
  const prefersDarkMode = false //useMediaQuery("(prefers-color-scheme: dark)")

  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? "dark" : "light",
        },
      }),
    [prefersDarkMode]
  )
  return theme
}
