import {
  AccountCircle,
  AspectRatio,
  CalendarToday,
  Search,
} from "@mui/icons-material"
import MenuIcon from "@mui/icons-material/Menu"
import { AppBar, Menu, MenuItem, Toolbar, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Drawer from "@mui/material/Drawer"
import IconButton from "@mui/material/IconButton"
import List from "@mui/material/List"
import ListItem from "@mui/material/ListItem"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemIcon from "@mui/material/ListItemIcon"
import ListItemText from "@mui/material/ListItemText"
import { useState } from "react"
import { NavLink, useNavigate } from "react-router-dom"
import { User } from "services/types"
import { useLogoutMutation } from "services/userApi"

export function TopBar({ user }: { user: User }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const navigate = useNavigate()

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const [logout] = useLogoutMutation()
  const handleLogout = () => {
    logout({}).then(() => navigate("/signin"))
  }

  const [menuShown, showMenu] = useState(false)

  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return
      }
      showMenu(open)
    }

  const topMenu = () => (
    <Box
      sx={{ width: "auto" }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem key={"hands"} disablePadding>
          <ListItemButton to="/hand-histories" component={NavLink}>
            <ListItemIcon>
              <Search />
            </ListItemIcon>
            <ListItemText primary={"Hands"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"simulations"} disablePadding>
          <ListItemButton to="/simulations" component={NavLink}>
            <ListItemIcon>
              <CalendarToday />
            </ListItemIcon>
            <ListItemText primary={"Simulations"} />
          </ListItemButton>
        </ListItem>
        <ListItem key={"ranges"} disablePadding>
          <ListItemButton to="/ranges" component={NavLink}>
            <ListItemIcon>
              <AspectRatio />
            </ListItemIcon>
            <ListItemText primary={"Ranges"} />
          </ListItemButton>
        </ListItem>
      </List>
    </Box>
  )

  return (
    <AppBar
      position="static"
      sx={{
        "&.MuiAppBar-root": {
          background: "#666666",
        },
      }}
    >
      <Toolbar>
        <IconButton
          aria-label="delete"
          color="primary"
          onClick={toggleDrawer(true)}
          sx={{ color: "white", display: { xs: "block", sm: "none" } }}
        >
          <MenuIcon />
        </IconButton>
        <Drawer anchor={"top"} open={menuShown} onClose={toggleDrawer(false)}>
          {topMenu()}
        </Drawer>

        <Box sx={{ flexGrow: 1 }}></Box>

        <div>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
            sx={{ height: "60px" }}
          >
            {user.imageUrl && (
              <img
                src={user.imageUrl}
                style={{ height: "100%", borderRadius: "8px" }}
              />
            )}
            {!user.imageUrl && <AccountCircle />}
            <Typography variant="h6" ml={1}>
              {user.name}
            </Typography>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem component={NavLink} to="/profile">
              Profile
            </MenuItem>
            <MenuItem onClick={handleLogout}>Logout</MenuItem>
          </Menu>
        </div>
      </Toolbar>
    </AppBar>
  )
}
