import { api } from "./api"
import { RangeInfo, RangeSet, SaveRangeRequest } from "./types"

const rangesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getStrategies: builder.query<RangeSet[], void>({
      query: () => "api/preflop/rangeset/",
      providesTags: ["RangeStretegies"],
    }),
    deleteStrategy: builder.mutation<void, string>({
      query: (strategyId: string) => ({
        url: `api/preflop/rangeset/${strategyId}`,
        method: "delete",
      }),
      invalidatesTags: ["RangeStretegies"],
    }),
    cloneStrategy: builder.mutation<
      RangeSet,
      Partial<RangeSet> & Pick<RangeSet, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `api/preflop/rangeset/${id}/clone`,
        method: "post",
        body: patch,
      }),
      invalidatesTags: ["RangeStretegies"],
    }),
    changeStrategy: builder.mutation<
      RangeSet,
      Partial<RangeSet> & Pick<RangeSet, "id">
    >({
      query: ({ id, ...patch }) => ({
        url: `api/preflop/rangeset/${id}`,
        method: "PATCH",
        body: patch,
      }),
      invalidatesTags: ["RangeStretegies"],
    }),
    range: builder.mutation<RangeInfo, Record<string, string | number>>({
      query: (body) => ({
        url: "api/preflop/range",
        method: "post",
        body,
      }),
    }),
    saveRange: builder.mutation<RangeInfo, SaveRangeRequest>({
      query: (body) => ({
        url: "api/preflop/range/save",
        method: "post",
        body,
      }),
    }),
    deleteStackSize: builder.mutation<void, { rangeset: string; size: string }>(
      {
        query: ({ rangeset, size }) => ({
          url: `api/preflop/rangeset/${rangeset}/stacksize/${size}`,
          method: "delete",
        }),
        invalidatesTags: ["RangeStretegies"],
      }
    ),
  }),
})

export const {
  useGetStrategiesQuery,
  useRangeMutation,
  useDeleteStrategyMutation,
  useCloneStrategyMutation,
  useChangeStrategyMutation,
  useSaveRangeMutation,
  useDeleteStackSizeMutation,
} = rangesApi
