import { RangeInfo } from "services/types"

export function getCellId(row: number, cell: number): string {
  if (row > cell) {
    return Cards[cell] + Cards[row] + "o"
  } else if (row < cell) {
    return Cards[row] + Cards[cell] + "s"
  }
  return Cards[row] + Cards[cell]
}

import { Action, ActionRatios, Cards, Suits } from "./types"
export function getActionRatio(
  ratio: ActionRatios,
  action: Action,
  filterAction: Action | undefined
): number {
  const actionRatio = ratio[action]
  if (filterAction && filterAction !== action) return 0
  return actionRatio !== undefined && actionRatio > 0 ? actionRatio : 0
}

export function checkRatio(ratio: ActionRatios): {
  valid: boolean
  totalPercent: number
} {
  let total = 0
  for (const action in ratio) {
    const percent = ratio[action as Action]
    total += Math.trunc((percent || 0) * 100)
  }
  if (total > 100) {
    console.log(`bad ${total} ${ratio}`)
  }
  return {
    totalPercent: total,
    valid: !(total > 100),
  }
}

export function toPercent(val: number): string {
  return (val * 100).toFixed(0)
}

export function getCellBackground(
  ratio: ActionRatios | undefined,
  filterAction: Action | undefined,
  scaleWrongRatios: boolean | undefined
): string {
  if (ratio === undefined || ratio === null) {
    return "linear-gradient(#444444 0%, #444444 100%)"
  }

  const { valid, totalPercent: total } = checkRatio(ratio)
  let scale = 100
  if (!valid) {
    scale = (100 * 100) / total
    if (!scaleWrongRatios) return ""
  }

  let percent = 0
  let result = "linear-gradient(to right, "

  const allInPercent: number = getActionRatio(ratio, "ALL_IN", filterAction)
  if (allInPercent) {
    const newPercent = percent + allInPercent * scale
    result = result + "DarkRed " + percent + "%, DarkRed " + newPercent + "%"
    percent = newPercent
  }

  const raisePercent: number = getActionRatio(ratio, "RAISE", filterAction)
  if (raisePercent) {
    if (percent > 0) {
      result = result + ", "
    }
    const newPercent = percent + raisePercent * scale
    result = result + "Orange " + percent + "%, Orange " + newPercent + "%"
    percent = newPercent
  }

  const callPercent: number = getActionRatio(ratio, "CALL", filterAction)
  if (callPercent) {
    if (percent > 0) {
      result = result + ", "
    }
    const newPercent = percent + callPercent * scale
    result = result + "SeaGreen " + percent + "%, SeaGreen " + newPercent + "%"
    percent = newPercent
  }

  const foldPercent: number = getActionRatio(ratio, "FOLD", filterAction)
  if (foldPercent) {
    if (percent > 0) {
      result = result + ", "
    }
    const newPercent = percent + foldPercent * scale
    result = result + "DarkGray " + percent + "%, DarkGray " + newPercent + "%"
    percent = newPercent
  }

  if (percent < 100) {
    if (percent > 0) {
      result = result + ", "
    }
    result = result + "#444444 " + percent + "%, #444444 100%"
  }
  result = result + ")"
  return result
}

export function getDefinedActions(range: RangeInfo) {
  const rv = new Set<Action>()
  const cards = range.ranges
  for (const cardId in cards) {
    const cardInfo = cards[cardId]
    if (!cardInfo) continue
    for (const actionName in cardInfo) {
      const ratio = cardInfo[actionName as Action]
      if (!ratio || !(ratio > 0)) continue
      rv.add(actionName as Action)
    }
  }
  return Array.from(rv).sort()
}

export function getAvailableActions(): Action[] {
  const rv = new Set<Action>()
  rv.add("ALL_IN")
  rv.add("RAISE")
  rv.add("CALL")
  rv.add("FOLD")
  return Array.from(rv)
}

export function validPIOCombo(combo: string): {
  valid: boolean
  combos?: string[]
} {
  // AA, KTo, 53s, 53, ...
  if (combo.length != 2 && combo.length != 3) return { valid: false }
  if (combo.length == 2) {
    if (Cards.indexOf(combo[0]) < 0 || Cards.indexOf(combo[1]) < 0)
      return { valid: false }
    if (combo[0] != combo[1]) {
      return { valid: true, combos: [`${combo}o`, `${combo}s`] }
    }
  }

  if (combo.length == 3) {
    if (combo[0] == combo[1]) return { valid: false }
    if (combo[2] != "o" && combo[2] != "s") return { valid: false }
    if (Cards.indexOf(combo[0]) < 0 || Cards.indexOf(combo[1]) < 0)
      return { valid: false }
  }

  return { valid: true, combos: [combo] }
}

export function validMonkerCombo(combo: string): {
  valid: boolean
  combos?: string[]
} {
  // AsJs,...
  if (combo.length != 4) return { valid: false }
  if (Cards.indexOf(combo[0]) < 0 || Cards.indexOf(combo[2]) < 0)
    return { valid: false }

  // suit in [d|h|c|s]
  if (Suits.indexOf(combo[1]) < 0 || Suits.indexOf(combo[3]) < 0)
    return { valid: false }

  if (combo[0] == combo[2]) {
    return { valid: true, combos: [`${combo[0]}${combo[0]}`] }
  }

  if (combo[1] == combo[3]) {
    // same suit
    return { valid: true, combos: [`${combo[0]}${combo[2]}s`] }
  } else {
    return { valid: true, combos: [`${combo[0]}${combo[2]}o`] }
  }
}
