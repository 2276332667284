import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { useEffect, useMemo } from "react"
import { RangeSet } from "services/types"
import { ActionKind } from "./Ranges"
import { CATEGORIES, HERO_POSITIONS, Position, RangeParameters } from "./types"

export interface RangeParametersProps {
  handleChange: (field: ActionKind, value: string | Position) => void
  state: RangeParameters
  strategies: RangeSet[]
}

export function RangeParametersView(props: RangeParametersProps) {
  const { state, handleChange, strategies } = props

  useEffect(() => {
    if (state.rangeset === undefined && strategies.length > 0) {
      handleChange("rangeset", strategies[0].id)
    }
  }, [state, strategies, handleChange])

  const strategyInfo = useMemo(() => {
    const rv = new Map<string, RangeSet>()
    strategies.forEach((v) => {
      rv.set(v.id, v)
    })
    return rv
  }, [strategies])

  const stackSizes = useMemo(() => {
    if (state.rangeset === undefined || strategyInfo === undefined) return []
    const rangeset = strategyInfo.get(state.rangeset)
    if (rangeset === undefined) return []
    const n_sizes = rangeset.sizes.map((v) => v.size).sort((a, b) => a - b)
    return n_sizes.map((v) => v.toString())
  }, [state, strategyInfo])

  const positions = useMemo(() => {
    if (state.rangeset === undefined || strategyInfo === undefined) return []
    const rangeset = strategyInfo.get(state.rangeset)
    if (rangeset === undefined) return []
    if (state.stackSize === undefined) return []
    const sizeInfo = rangeset.sizes.filter(
      (v) => v.size.toString() == state.stackSize
    )[0]
    if (sizeInfo === undefined) return []
    return HERO_POSITIONS.map((v) => {
      const disabled =
        sizeInfo.available_position.indexOf(v.position) < 0 ||
        v.position === "BB"
      v.enabled = !disabled
      return v
    })
  }, [state, strategyInfo])

  useEffect(() => {
    if (
      state.stackSize === undefined &&
      stackSizes !== undefined &&
      stackSizes.length > 0
    )
      handleChange("stackSize", stackSizes[0])
    if (
      state.heroPosition === undefined &&
      positions !== undefined &&
      positions.length > 0
    )
      handleChange("heroPosition", positions[0].position)
  }, [state, stackSizes, positions, handleChange])

  return (
    <Box
      sx={{
        ml: { xs: 0, md: 2 },
        mt: { xs: 2, md: 0 },
        p: 2,
        borderRadius: "12px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#EEEEEE",
      }}
    >
      <Box>
        <Box>RangeSet</Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {strategies.map((rangeset) => (
            <Box
              key={rangeset.id}
              sx={{
                mr: "10px",
                mt: "10px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  minWidth: "100px",
                  borderRadius: "50px",
                  "&.MuiButton-root.MuiButton-contained": {
                    backgroundColor: "darkcyan",
                  },
                }}
                variant={
                  state.rangeset !== rangeset.id ? "outlined" : "contained"
                }
                // TODO disabled state based on other filters data and backend
                onClick={() => handleChange("rangeset", rangeset.id)}
              >
                {rangeset.name}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box>Stack size in BB</Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {stackSizes.map((size) => (
            <Box key={size} sx={{ mr: "10px", mb: "10px" }}>
              <Button
                sx={{
                  textTransform: "none",
                  minWidth: "60px",
                  borderRadius: "50px",
                  "&.MuiButton-root.MuiButton-contained": {
                    backgroundColor: "darkcyan",
                  },
                }}
                variant={state.stackSize !== size ? "outlined" : "contained"}
                onClick={() => handleChange("stackSize", size)}
              >
                {size}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>

      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box>Category</Box>
        <Box sx={{ display: "flex" }}>
          {CATEGORIES.map((categoryInfo) => (
            <Box key={categoryInfo.category} sx={{ mr: "10px", mb: "10px" }}>
              <Button
                sx={{
                  textTransform: "none",
                  minWidth: "100px",
                  borderRadius: "50px",
                  "&.MuiButton-root.MuiButton-contained": {
                    backgroundColor: "darkcyan",
                  },
                }}
                variant={
                  state.category !== categoryInfo.category
                    ? "outlined"
                    : "contained"
                }
                disabled={true}
                onClick={() => handleChange("category", categoryInfo.category)}
              >
                {categoryInfo.label}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
      <Box
        sx={{
          mt: 2,
        }}
      >
        <Box>Position</Box>
        <Box sx={{ display: "flex", flexWrap: "wrap" }}>
          {positions.map((positionInfo) => (
            <Box
              key={positionInfo.position}
              sx={{
                mr: "10px",
                mt: "10px",
              }}
            >
              <Button
                sx={{
                  textTransform: "none",
                  "&.MuiButton-root": {
                    fontSize: "10px",
                    minWidth: "40px",
                    maxWidth: "40px",
                    minHeight: "40px",
                    borderRadius: "50%",
                  },
                  "&.MuiButton-root.MuiButton-contained": {
                    backgroundColor: "darkcyan",
                  },
                }}
                variant={
                  state.heroPosition !== positionInfo.position
                    ? "outlined"
                    : "contained"
                }
                disabled={!positionInfo.enabled}
                onClick={() =>
                  handleChange("heroPosition", positionInfo.position)
                }
              >
                {positionInfo.label}
              </Button>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}
