import DeleteIcon from "@mui/icons-material/Delete"
import PencilIcon from "@mui/icons-material/Edit"
import PlayIcon from "@mui/icons-material/PlayArrow"
import { Box, Button, IconButton } from "@mui/material"
import { styled } from "@mui/system"
import { DataGrid, GridColDef } from "@mui/x-data-grid"
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query"
import { ChangeEvent, useCallback } from "react"
import { useDispatch } from "react-redux"
import { useSearchParams } from "react-router-dom"
import { api } from "services/api"
import { useHandHistoryQuery } from "services/handHistoryApi"
import { TEST_DATA } from "./TestData"

const columns: GridColDef[] = [
  { field: "id", headerName: "ID", width: 280, editable: false, hide: false },
  {
    field: "source",
    headerName: "Source",
    width: 120,
    editable: true,
    hide: true,
  },
  {
    field: "playedOn",
    headerName: "Played on",
    width: 140,
    editable: true,
    type: "date",
    hide: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
  },
  {
    field: "importedOn",
    headerName: "Imported on",
    width: 150,
    editable: true,
    type: "date",
    hide: true,
    valueFormatter: (params) => new Date(params.value).toLocaleDateString(),
  },
  {
    field: "tournamentId",
    headerName: "Tournament ID",
    width: 150,
    editable: true,
    hide: true,
  },
  {
    field: "sourceHandId",
    headerName: "Source Hand ID",
    width: 150,
    editable: true,
    hide: true,
  },
  {
    field: "playerCards",
    headerName: "Player's Cards",
    width: 60,
    editable: true,
    hide: false,
  },
  {
    field: "playerCardsImg",
    headerName: "Player's Cards View",
    width: 100,
    valueGetter: (params) =>
      params.row.playerCards
        .replace(/(c|s|h|d)/g, "$1 ")
        .trim()
        .split(" "),
    renderCell: (params) => params.value.map((c: string) => cardToEmoji(c)),
    sortable: false,
  },
  {
    field: "boardCards",
    headerName: "Board Cards",
    width: 120,
    editable: true,
    hide: false,
  },
  {
    field: "boardCardsImg",
    headerName: "Board Cards View",
    width: 250,
    valueGetter: (params) =>
      params.row.boardCards
        .replace(/(c|s|h|d)/g, "$1 ")
        .trim()
        .split(" "),
    renderCell: (params) => params.value.map((c: string) => cardToEmoji(c)),
    sortable: false,
  },
  {
    field: "pot",
    headerName: "Pot",
    width: 100,
    type: "number",
  },
  {
    field: "effectiveStack",
    headerName: "Effective Stack",
    width: 160,
    type: "number",
  },
  {
    field: "position",
    headerName: "Position",
    width: 100,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 120,
    sortable: false,
    renderCell: () => (
      <>
        <IconButton size="small" aria-label="delete" title="Run Simulation">
          <PlayIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="delete">
          <PencilIcon fontSize="small" />
        </IconButton>
        <IconButton size="small" aria-label="delete">
          <DeleteIcon fontSize="small" />
        </IconButton>
      </>
    ),
  },
  {
    field: "results",
    headerName: "Results",
    width: 80,
    sortable: false,
    renderCell: () => "Sim results",
  },
]

const Info = styled("div")({
  background: "green",
  color: "white",
  padding: "4px 8px",
  borderRadius: 4,
  marginLeft: "auto",
})

const Error = styled(Info)({
  background: "red",
})

export function HandHistories() {
  const [params] = useSearchParams()
  const { isLoading, isFetching, data, isError, error } = useHandHistoryQuery()
  const dispatch = useDispatch()
  const handleImport = useCallback(
    (event: ChangeEvent) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file == null) {
        return
      }
      const url = "/api/hand-history/upload/acr"
      const formData = new FormData()
      formData.append("file", file)
      formData.append("fileName", file.name)
      fetch(url, { body: formData, method: "POST" }).then(() => {
        dispatch(api.util.invalidateTags(["HandHistory"]))
      })
    },
    [dispatch]
  )

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      <Box
        sx={{ display: "flex", gap: 1, marginBottom: 1, alignItems: "center" }}
      >
        <input
          style={{ display: "none" }}
          id="import-file"
          multiple
          type="file"
          onChange={handleImport}
        />
        <label htmlFor="import-file">
          <Button component="span">Import</Button>
        </label>
        {(isLoading || isFetching) && <Info>Loading…</Info>}
        {isError && (
          <Error>
            {(error as FetchBaseQueryError).status}{" "}
            {"data" in error &&
              (error as { data: { error: string } }).data.error}
          </Error>
        )}
      </Box>
      <DataGrid
        rows={params.has("test") ? TEST_DATA : data || []}
        columns={columns}
        disableSelectionOnClick
        experimentalFeatures={{ newEditingApi: true }}
      />
    </Box>
  )
}

function cardToEmoji(card: string) {
  const rank = card.slice(0, card.length - 1)
  const suit = card[card.length - 1]
  let pos = 0
  switch (rank) {
    case "A":
      pos = 0
      break
    case "T":
      pos = 9
      break
    case "J":
      pos = 10
      break
    case "Q":
      pos = 11
      break
    case "K":
      pos = 12
      break
    default:
      pos = parseInt(rank) - 1
  }
  let mult = 0
  switch (suit) {
    case "s":
      mult = 0
      break
    case "h":
      mult = 1
      break
    case "d":
      mult = 2
      break
    case "c":
      mult = 3
      break
    default:
      mult = 0
  }
  return (
    <span
      style={{
        fontSize: 40,
        color: suit === "h" || suit === "d" ? "red" : "black",
      }}
    >
      {CARDS_EMOJI[mult * 13 + pos]}
    </span>
  )
}

const CARDS_EMOJI = [
  "🂡",
  "🂢",
  "🂣",
  "🂤",
  "🂥",
  "🂦",
  "🂧",
  "🂨",
  "🂩",
  "🂪",
  "🂫",
  "🂭",
  "🂮",
  "🂱",
  "🂲",
  "🂳",
  "🂴",
  "🂵",
  "🂶",
  "🂷",
  "🂸",
  "🂹",
  "🂺",
  "🂻",
  "🂽",
  "🂾",
  "🃁",
  "🃂",
  "🃃",
  "🃄",
  "🃅",
  "🃆",
  "🃇",
  "🃈",
  "🃉",
  "🃊",
  "🃋",
  "🃍",
  "🃎",
  "🃑",
  "🃒",
  "🃓",
  "🃔",
  "🃕",
  "🃖",
  "🃗",
  "🃘",
  "🃙",
  "🃚",
  "🃛",
  "🃝",
  "🃞",
]
