import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useRangeMutation } from "services/rangesApi"
import { CardInfoView } from "./CardInfoView"
import { CardInfo } from "./Ranges"
import {
  Action,
  ACTION_COLORS,
  ACTION_NAMES,
  Cards,
  RangeParameters,
} from "./types"
import { getCellBackground, getCellId, getDefinedActions } from "./utils"

interface RangeAction {
  action: Action
  percent?: number
  combinations?: number
}

export interface RangeViewProps {
  parameters: RangeParameters
}

export function RangeView(props: RangeViewProps) {
  const { parameters } = props

  const [filterAction, setActionFilter] = useState<Action | undefined>(
    undefined
  )
  const [currentCard, setCurrentCard] = useState<CardInfo | undefined>(
    undefined
  )
  const [getRange, { data, isLoading, error }] = useRangeMutation()

  useEffect(() => {
    getRange({
      rangeset_id: parameters.rangeset || "",
      stack_size: Number(parameters.stackSize) || 0,
      position: parameters.heroPosition || "",
      category: parameters.category || "",
    })
    setCurrentCard(undefined)
  }, [getRange, parameters])

  const selectCell = useCallback(
    (card: CardInfo | undefined) => {
      setCurrentCard(card)
    },
    [setCurrentCard]
  )

  const rows = useMemo(() => {
    if (!data) {
      return []
    }
    const rows = []
    for (let i = 0; i < Cards.length; i++) {
      const cells = []
      for (let j = 0; j < Cards.length; j++) {
        const cellId: string = getCellId(i, j)
        const decimalRatio = data.ranges[cellId]
        const ratio: Record<string, number> = {}
        for (const op in decimalRatio) {
          ratio[op] = decimalRatio[op]
        }
        const cell = (
          <Box
            sx={{
              padding: { xs: "1px", sm: "2px", md: "4px" },
              fontSize: { xs: "10px", sm: "12px", md: "14px" },
            }}
            onClick={() => selectCell({ cardId: cellId, ratio: ratio })}
            key={cellId}
            className="range-cell"
            style={{
              background: getCellBackground(ratio, filterAction, true),
            }}
          >
            <Box
              sx={
                {
                  // transform: i == j ? "rotate(20deg)" : "none",
                }
              }
            >
              {cellId}
            </Box>
          </Box>
        )
        cells.push(cell)
      }
      const row = (
        <div key={i} className="range-row">
          {cells}
        </div>
      )
      rows.push(row)
    }
    return rows
  }, [filterAction, selectCell, data])

  const actions: RangeAction[] = useMemo(() => {
    const definedActions: Action[] = !data ? [] : getDefinedActions(data)
    const rv: RangeAction[] = definedActions.map((action): RangeAction => {
      return {
        action: action,
        combinations: (data?.operationCombos || {})[action],
      }
    })

    let totalCombos = 0
    rv.map((op) => {
      if (op.combinations && op.combinations > 0) totalCombos += op.combinations
    })
    rv.map((op) => {
      if (op.combinations && op.combinations > 0)
        op.percent = (op.combinations / totalCombos) * 100
    })
    return rv
  }, [data])

  return (
    <Box
      sx={{
        flexGrow: 1,
        p: 2,
        borderRadius: "12px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#EEEEEE",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Box sx={{ fontWeight: "bold" }}>
              {parameters.stackSize}bb {parameters.heroPosition}{" "}
              {parameters.category}
            </Box>
            {/* TODO real sizing  */}
            <Box sx={{ pl: 1, fontSize: "12px" }}>
              Raise sizing: {data?.raiseSizing.toFixed(2)}xx
            </Box>
          </Box>
          <Box sx={{ display: "flex", flexWrap: "wrap", pt: 1 }}>
            {actions.map((op) => (
              <Box key={op.action} sx={{ mr: "10px" }}>
                <Button
                  size="small"
                  sx={{
                    mt: "4px",
                    textTransform: "none",
                    "&.MuiButton-root": {
                      minWidth: "170px",
                      minHeight: "20px",
                      borderRadius: "8px",
                      justifyContent: "flex-start",
                    },
                    "&.MuiButton-root.MuiButton-contained": {
                      backgroundColor: "darkcyan",
                    },
                  }}
                  variant={
                    filterAction !== op.action ? "outlined" : "contained"
                  }
                  onClick={() =>
                    setActionFilter(
                      op.action !== filterAction ? op.action : undefined
                    )
                  }
                >
                  <Box
                    sx={{
                      minWidth: "14px",
                      minHeight: "14px",
                      border: "1px solid #333333",
                      borderRadius: "50%",
                      background: ACTION_COLORS[op.action],
                    }}
                  ></Box>
                  <Box sx={{ pl: "4px", flexWrap: "nowrap", display: "flex" }}>
                    {ACTION_NAMES[op.action]}
                    {op.combinations && op.combinations > 0 && (
                      <Box
                        component="span"
                        sx={{ display: "flex", flexWrap: "nowrap" }}
                      >
                        <Box
                          component="span"
                          sx={{ pl: "2px", color: "darkgrey" }}
                        >
                          {op.percent?.toFixed(2)}%
                        </Box>
                        <Box
                          component="span"
                          sx={{ pl: "2px", color: "darkgrey" }}
                        >
                          ({op.combinations?.toFixed(0)})
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Button>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
      <Box sx={{ pt: 2 }}>{rows}</Box>
      <Box sx={{ pt: 2 }}></Box>
      {currentCard && (
        <CardInfoView cardId={currentCard?.cardId} ratio={currentCard?.ratio} />
      )}
    </Box>
  )
}
