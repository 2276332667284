import { AspectRatio, CalendarToday, Search } from "@mui/icons-material"
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material"
import Box from "@mui/material/Box"
import { Location, useLocation } from "react-router"
import { NavLink } from "react-router-dom"

export function LeftNavigation() {
  const location = useLocation()

  const menuItems = [
    {
      label: "Hands",
      icon: <Search />,
      link: "/hand-histories",
      isActive: (location: Location) => location.pathname === "/hand-histories",
    },
    {
      label: "Simulations",
      icon: <CalendarToday />,
      link: "/simulations",
      isActive: (location: Location) => location.pathname === "/simulations",
    },
    {
      label: "Ranges",
      icon: <AspectRatio />,
      link: "/ranges",
      isActive: (location: Location) => location.pathname === "/ranges",
    },
  ]

  return (
    <Box
      sx={{
        overflow: "auto",
        background: "#EEEEEE",
        display: { xs: "none", sm: "block" },
      }}
    >
      <List>
        {menuItems.map(({ label, icon, link, isActive }) => (
          <ListItem key={label} disablePadding>
            <ListItemButton
              component={link ? NavLink : "span"}
              selected={isActive && isActive(location)}
              to={link}
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    "&.MuiListItemIcon-root": {
                      color: "inherit",
                    },
                  }}
                >
                  {icon}
                </ListItemIcon>
                <ListItemText primary={label} />
              </Box>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )
}
