import { api } from "./api"
import { HandHistory } from "./types"

const handHistoryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    handHistory: builder.query<HandHistory[], void>({
      query: () => "api/hand-history",
      providesTags: ["HandHistory"],
    }),
  }),
})

export const { useHandHistoryQuery } = handHistoryApi
