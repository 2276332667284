import Box from "@mui/material/Box"
import { useCallback, useMemo, useReducer, useState } from "react"
import { useGetStrategiesQuery } from "services/rangesApi"
import { RangeParametersView } from "./RangeParametersView"
import "./Ranges.css"
import { RangeView } from "./RangeView"
// import { RANGES_TEST_DATA } from "./TestData"
import FormControlLabel from "@mui/material/FormControlLabel"
import Switch from "@mui/material/Switch"
import { RangeSet } from "services/types"
import { RangeEdit } from "./RangeEdit"
import { RangeParametersEdit } from "./RangeParametersEdit"
import { ManageStrategiesToolbar } from "./RangesToolbar"
import { ActionRatios, Position, RangeParameters } from "./types"

export interface CardInfo {
  cardId: string
  ratio?: ActionRatios
}

const initialState: RangeParameters = {
  category: "RFI",
}
export type ActionKind = "stackSize" | "category" | "heroPosition" | "rangeset"

type ReducerAction = {
  type: ActionKind
  payload: string | Position
}

function reducer(
  state: RangeParameters,
  action: ReducerAction
): RangeParameters {
  const { type, payload } = action
  switch (type) {
    default:
      return {
        ...state,
        [type]: payload,
      }
  }
}

export interface RangeViewerProps {
  handleChange: (field: ActionKind, value: string | Position) => void
  parameters: RangeParameters
  strategies: RangeSet[]
}

function RangeViewer(props: RangeViewerProps) {
  const { handleChange, parameters, strategies } = props
  const filtersFilled =
    parameters.stackSize && parameters.heroPosition && parameters.rangeset
  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          flexGrow: { xs: 0, md: 1 },
          minWidth: "450px",
          display: "flex",
          alignItems: filtersFilled ? "flex-start" : "center",
          justifyContent: "center",
        }}
      >
        {(filtersFilled && <RangeView parameters={parameters} />) || (
          <Box>Please, select filters.</Box>
        )}
      </Box>

      <RangeParametersView
        handleChange={handleChange}
        state={parameters}
        strategies={strategies}
      />
    </Box>
  )
}

export interface RangeEditorProps {
  handleChange: (field: ActionKind, value: string | Position) => void
  parameters: RangeParameters
  strategies: RangeSet[]
}

function RangeEditor(props: RangeEditorProps) {
  const { handleChange, parameters, strategies } = props
  const filtersFilled =
    parameters.stackSize && parameters.heroPosition && parameters.rangeset

  const rangeset = useMemo(() => {
    for (const current of strategies) {
      if (current.id == parameters.rangeset) return current
    }
    return undefined
  }, [strategies, parameters])

  return (
    <Box
      sx={{
        display: "flex",
        flexGrow: 1,
        flexDirection: {
          xs: "column",
          md: "row",
        },
      }}
    >
      <Box
        sx={{
          flexGrow: { xs: 0, md: 1 },
          minWidth: "450px",
          display: "flex",
          alignItems: filtersFilled ? "flex-start" : "center",
          justifyContent: "center",
        }}
      >
        {(filtersFilled && <RangeEdit parameters={parameters} />) || (
          <Box>Please, select filters.</Box>
        )}
      </Box>
      {rangeset && (
        <RangeParametersEdit
          handleChange={handleChange}
          state={parameters}
          // strategies={strategies}
          rangeset={rangeset}
        />
      )}
    </Box>
  )
}

export interface RangesProps {
  manageable?: boolean
}

export function Ranges(props: RangesProps) {
  const { manageable } = props
  const [parameters, dispatch] = useReducer(reducer, initialState)
  const [inEditor, switchToEditor] = useState(false)
  // const user = useAuthUser()

  const showEditor = (event: React.ChangeEvent<HTMLInputElement>) => {
    switchToEditor(event.target.checked)
  }

  const {
    data: strategies,
    // isLoading,
    isSuccess: haveStrategies,
    // isError,
  } = useGetStrategiesQuery()

  const handleChange = useCallback(
    (field: ActionKind, value: string | Position) => {
      dispatch({
        type: field,
        payload: value,
      })
    },
    [dispatch]
  )

  if (!haveStrategies) {
    return <div>Loading...</div>
  }

  if (haveStrategies && strategies.length == 0) {
    return <div>No strategies defined in system...</div>
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        flexGrow: 1,
      }}
    >
      {manageable && (
        <>
          <ManageStrategiesToolbar
            parameters={parameters}
            strategies={strategies}
          />
          <FormControlLabel
            control={
              <Switch
                checked={inEditor}
                onChange={showEditor}
                inputProps={{ "aria-label": "controlled" }}
              />
            }
            label="Show Editor"
          />
        </>
      )}
      {!inEditor && (
        <RangeViewer
          strategies={strategies}
          parameters={parameters}
          handleChange={handleChange}
        />
      )}
      {inEditor && parameters.rangeset && (
        <RangeEditor
          strategies={strategies}
          parameters={parameters}
          handleChange={handleChange}
        />
      )}
    </Box>
  )
}
