import { api } from "./api"
import {
  ChangePasswordRequest,
  LoginRequest,
  RegisterRequest,
  ResetPasswordRequest,
  ResetPasswordResponse,
  SendResetPasswordLinkRequest,
  SendResetPasswordLinkResponse,
  User,
  VerifyRegisterRequest,
} from "./types"

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation<void, LoginRequest>({
      query: (body) => ({ url: "api/users/login/", method: "post", body }),
      invalidatesTags: ["User"],
    }),

    register: builder.mutation<void, RegisterRequest>({
      query: (body) => ({ url: "api/users/register/", method: "post", body }),
      invalidatesTags: ["User"],
    }),

    verifyUser: builder.mutation<User, VerifyRegisterRequest>({
      query: (body) => ({
        url: "verify-registration/",
        method: "post",
        body,
      }),
      invalidatesTags: ["User"],
    }),

    changePassword: builder.mutation<void, ChangePasswordRequest>({
      query: (body) => ({
        url: "api/users/change-password/",
        method: "post",
        body,
      }),
    }),

    sendResetPasswordLink: builder.mutation<
      SendResetPasswordLinkResponse,
      SendResetPasswordLinkRequest
    >({
      query: (body) => ({
        url: "send-reset-password-link/",
        method: "post",
        body,
      }),
    }),

    resetPassword: builder.mutation<
      ResetPasswordResponse,
      ResetPasswordRequest
    >({
      query: (body) => ({
        url: "api/users/reset-password/",
        method: "post",
        body,
      }),
    }),

    logout: builder.mutation({
      query: () => ({ url: "logout", method: "post" }),
      invalidatesTags: ["User"],
    }),

    profile: builder.query<User, void>({
      query: () => "user/me/",
      providesTags: ["User"],
    }),
  }),
})

export const {
  useLoginMutation,
  useRegisterMutation,
  useVerifyUserMutation,
  useChangePasswordMutation,
  useSendResetPasswordLinkMutation,
  useResetPasswordMutation,
  useLogoutMutation,
  useProfileQuery,
} = userApi
