export const Cards: string[] = [
  "A",
  "K",
  "Q",
  "J",
  "T",
  "9",
  "8",
  "7",
  "6",
  "5",
  "4",
  "3",
  "2",
]

export const Suits: string[] = ["d", "h", "c", "s"]

export const ACTION_COLORS = {
  ALL_IN: "darkred",
  RAISE: "orange",
  CALL: "seagreen",
  FOLD: "darkgray",
}

export const ACTION_NAMES = {
  ALL_IN: "AllIn",
  RAISE: "Raise",
  CALL: "Call",
  FOLD: "Fold",
}

export type Category = "RFI" | "vsRFI" | "vs3Bet"
export const CATEGORY_RFI: Category = "RFI"
export const CATEGORY_VSRFI: Category = "vsRFI"
export const CATEGORY_VS3BET: Category = "vs3Bet"

export const CATEGORIES = [
  {
    category: CATEGORY_RFI,
    label: "RFI",
  },
  {
    category: CATEGORY_VSRFI,
    label: "vs. RFI",
  },
  {
    category: CATEGORY_VS3BET,
    label: "vs. 3bet",
  },
]

export type Position =
  | "UTG"
  | "UTG+1"
  | "UTG+2"
  | "HJ"
  | "CO"
  | "BTN"
  | "SB"
  | "BB"

export const POSITIONS = [
  "UTG",
  "UTG+1",
  "UTG+2",
  "HJ",
  "CO",
  "BTN",
  "SB",
  "BB",
]

export const HERO_POSITIONS: {
  position: Position
  label: string
  enabled: boolean
}[] = [
  {
    position: "UTG",
    label: "UTG",
    enabled: true,
  },
  {
    position: "UTG+1",
    label: "UTG+1",
    enabled: true,
  },
  {
    position: "UTG+2",
    label: "UTG+2",
    enabled: true,
  },
  {
    position: "HJ",
    label: "HJ",
    enabled: true,
  },
  {
    position: "CO",
    label: "CO",
    enabled: true,
  },
  {
    position: "BTN",
    label: "BTN",
    enabled: true,
  },
  {
    position: "SB",
    label: "SB",
    enabled: true,
  },
  {
    position: "BB",
    label: "BB",
    enabled: true,
  },
]

export type Action = "ALL_IN" | "RAISE" | "CALL" | "FOLD"

export type ActionRatios = { [key in Action]?: number }

export interface HandActionsRatio {
  handCombinationId: string
  actionRatio: ActionRatios
}

export type RangeParameters = {
  rangeset?: string
  stackSize?: string
  category?: string
  heroPosition?: Position
}
