import "@fontsource/roboto/300.css"
import "@fontsource/roboto/400.css"
import "@fontsource/roboto/500.css"
import "@fontsource/roboto/700.css"
import AdminApp from "adminapp/AdminApp"
import { StrictMode } from "react"
import ReactDOM from "react-dom"
import { Provider } from "react-redux"
import { BrowserRouter, Route, Routes } from "react-router-dom"
import App from "./app/App"
import { store } from "./app/store"
import SignIn from "./features/auth/SignIn"
import "./index.css"
import * as serviceWorker from "./serviceWorker"

ReactDOM.render(
  <StrictMode>
    <Provider store={store}>
      <BrowserRouter basename={`${process.env.PUBLIC_URL}`}>
        <Routes>
          <Route path="/signin/" element={<SignIn />} />
          <Route
            path="/*"
            element={
              process.env.REACT_APP_WLPS_APPLICATION == "admin" ? (
                <AdminApp />
              ) : (
                <App />
              )
            }
          />
        </Routes>
      </BrowserRouter>
    </Provider>
  </StrictMode>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
