import ClearIcon from "@mui/icons-material/Clear"
import { IconButton } from "@mui/material"
import Box from "@mui/material/Box"
import TextField from "@mui/material/TextField"
import { useEffect, useState } from "react"
import { CardInfo } from "./Ranges"
import { Action, ACTION_COLORS } from "./types"
import { checkRatio, toPercent } from "./utils"

export interface CardInfoEditProps {
  card: CardInfo
  updateCallback: (card: CardInfo) => void
}

export function CardInfoEdit(props: CardInfoEditProps) {
  const { card, updateCallback } = props
  const { cardId, ratio } = card
  const [manualRatioError, setManualRatioError] = useState<string | undefined>()
  const actions: JSX.Element[] = []

  useEffect(() => {
    const { valid } = checkRatio(ratio || {})
    if (valid) setManualRatioError(undefined)
    else setManualRatioError("Sum of all actions should not be more than 100%")
  }, [ratio])

  const resetActionRatio = () => {
    updateCallback({
      cardId: cardId,
      ratio: {
        ALL_IN: 0,
        CALL: 0,
        FOLD: 0,
        RAISE: 0,
      },
    })
  }

  const setActionRatio = (action: Action, percent: number) => {
    const newVal = Object.assign({}, ratio, {
      [action]: (percent / 100).toFixed(4),
    })
    updateCallback({
      cardId: cardId,
      ratio: {
        ALL_IN: newVal.ALL_IN || 0,
        RAISE: newVal.RAISE || 0,
        FOLD: newVal.FOLD || 0,
        CALL: newVal.CALL || 0,
      },
    })
  }
  const setAllInRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("ALL_IN", Number(event.target.value))
  }

  const setRaiseRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("RAISE", Number(event.target.value))
  }

  const setFoldRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("FOLD", Number(event.target.value))
  }

  const setCallRatio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setActionRatio("CALL", Number(event.target.value))
  }

  const availActions: Action[] = ["ALL_IN", "RAISE", "CALL", "FOLD"]
  availActions.forEach((action) => {
    const percent = (
      (ratio !== undefined ? ratio[action as Action] || 0 : 0) * 100
    ).toFixed(0)
    const cell = (
      <Box
        key={action}
        sx={{
          width: { sm: "30px", xs: "25px" },
          height: { sm: "30px", xs: "25px" },
          fontSize: { xs: "10px", sm: "12px" },

          background: ACTION_COLORS[action as Action],
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box>{percent}%</Box>
      </Box>
    )

    actions.push(cell)
  })
  return (
    <Box
      sx={{
        minWidth: "160px",
        display: "flex",
        alignItems: "center",
        border: "1px solid #CCCCCC",
        borderRadius: "12px",
        p: 1,
        mt: { xs: 1, sm: 0 },
      }}
    >
      <Box sx={{ fontWeight: "bold", fontSize: "14px" }}>
        {cardId || "Select card"}
      </Box>
      <Box sx={{ display: "flex", pl: 0.5 }}>{actions}</Box>
      <Box>
        <Box sx={{ display: "flex", pl: 1 }}>
          <Box sx={{ width: "50px" }}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              variant="standard"
              value={toPercent(ratio?.ALL_IN || 0)}
              onChange={setAllInRatio}
              sx={{
                pr: 1,
                "& .MuiInput-input": {
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box sx={{ width: "50px" }}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              variant="standard"
              value={toPercent(ratio?.RAISE || 0)}
              onChange={setRaiseRatio}
              sx={{
                pr: 1,
                "& .MuiInput-input": {
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box sx={{ width: "50px" }}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              variant="standard"
              value={toPercent(ratio?.CALL || 0)}
              onChange={setCallRatio}
              sx={{
                pr: 1,
                "& .MuiInput-input": {
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <Box sx={{ width: "50px" }}>
            <TextField
              autoFocus
              margin="dense"
              type="number"
              variant="standard"
              value={toPercent(ratio?.FOLD || 0)}
              onChange={setFoldRatio}
              sx={{
                pr: 1,
                "& .MuiInput-input": {
                  fontSize: "14px",
                },
              }}
            />
          </Box>
          <IconButton
            onClick={() => {
              resetActionRatio()
            }}
          >
            <ClearIcon />
          </IconButton>
        </Box>
        <Box>
          {manualRatioError && (
            <Box sx={{ color: "red" }}>{manualRatioError}</Box>
          )}
        </Box>
      </Box>
    </Box>
  )
}
