export type APIErrorResponse<RequestType> = {
  detail: string
} & Partial<RequestType>

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function getErrorData<RequestType>(error: any) {
  return error && "data" in error && error.data
    ? (error.data as APIErrorResponse<RequestType>)
    : undefined
}

export type LoginRequest = {
  login: string
  password: string
}

export type RegisterRequest = {
  username: string
  password: string
  email: string
  first_name: string
  last_name: string
}

export type VerifyRegisterRequest = {
  user_id: string
  timestamp: string
  signature: string
}

export type ChangePasswordRequest = {
  old_password: string
  password: string
  password_confirm: string
}

export type SendResetPasswordLinkRequest = {
  email: string
}

export type SendResetPasswordLinkResponse = {
  detail: string
}

export type ResetPasswordParams = {
  user_id: string
  timestamp: string
  signature: string
}

export type ResetPasswordRequest = {
  password: string
  password_confirm: string
} & ResetPasswordParams

export type ResetPasswordResponse = {
  detail: string
}

export type User = {
  id: number
  email: string
  name: string
  imageUrl: string
  systemAdmin: boolean
}

export interface HandHistory {
  id: number
  source: string
  playedOn: string
  importedOn: string
  tournamentId: string
  sourceHandId: string
  boardCards: string // "Kd6c9s8c3s"
  pot: number
  effectiveStack: number
  position: string // maybe union 'OOP' | ...
}

export interface StackSize {
  size: number
  available_position: string[]
}
export interface RangeSet {
  id: string
  name: string
  sizes: StackSize[]
}

export interface RangeInfo {
  raiseSizing: number
  ranges: Record<string, Record<string, number>>
  operationCombos?: Record<string, number>
}

export interface SaveRangeRequest {
  rangeset_id: string
  stack_size: number
  position: string
  category: string
  range: RangeInfo
}
