import { QueryStatus } from "@reduxjs/toolkit/query"
import { User } from "services/types"
import { useProfileQuery } from "services/userApi"

export function useAuthUser(): User | undefined {
  const profileQuery = useProfileQuery()

  return profileQuery.status === QueryStatus.fulfilled
    ? profileQuery.data
    : undefined
}
