import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import Profile from "features/auth/Profile"
import { Ranges } from "features/Ranges/Ranges"
import { useTheme } from "lib/useTheme"
import { useEffect } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useProfileQuery } from "services/userApi"
import { LeftNavigation } from "../adminapp/LeftNavigation"
import { TopBar } from "../app/TopBar"
import "./App.css"

const NotFound = () => {
  return <>Page not found</>
}
function AdminApp() {
  const theme = useTheme()

  const {
    isFetching: userIsFetching,
    data: user,
    error: userError,
  } = useProfileQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      (!userIsFetching && !user) ||
      (userError && "status" in userError && userError.status === 403)
    ) {
      navigate("/signin/")
    }
  })

  if (!user) return null

  if (user && !user.systemAdmin) {
    return <div>You do not have access to this application</div>
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <TopBar user={user} />

        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <LeftNavigation />

          <Box
            component="main"
            sx={{ flexGrow: 1, p: { xs: 0, sm: 3 }, display: "flex" }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/ranges" replace />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/ranges" element={<Ranges manageable={true} />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default AdminApp
