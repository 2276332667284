import { HandHistory } from "services/types"

const TEST_DATA: HandHistory[] = [
  {
    id: 0,
    source: "src",
    playedOn: "Mon Jul 06 2015 03:09:22 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Sat Apr 11 2020 12:16:10 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour173",
    sourceHandId: "srch2389",
    boardCards: "Ah9d4h6c7c",
    pot: 17,
    effectiveStack: 16,
    position: "OOP",
  },
  {
    id: 1,
    source: "src",
    playedOn: "Sat Feb 11 2017 19:25:16 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Apr 16 2016 23:40:12 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour21",
    sourceHandId: "srch1230",
    boardCards: "10sAcJcKc5d",
    pot: 13,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 2,
    source: "src",
    playedOn: "Thu Dec 21 2017 09:38:32 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Nov 14 2019 05:34:20 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour876",
    sourceHandId: "srch1873",
    boardCards: "QdQs4cKs7h",
    pot: 19,
    effectiveStack: 21,
    position: "OOP",
  },
  {
    id: 3,
    source: "src",
    playedOn: "Tue Aug 02 2022 19:13:19 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Nov 20 2021 15:08:27 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour62",
    sourceHandId: "srch3958",
    boardCards: "Ah4hQs9s4h",
    pot: 19,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 4,
    source: "src",
    playedOn: "Thu Jan 23 2020 12:05:49 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Mar 25 2015 02:34:47 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour234",
    sourceHandId: "srch3781",
    boardCards: "2h3c8c6dJd",
    pot: 17,
    effectiveStack: 26,
    position: "OOP",
  },
  {
    id: 5,
    source: "src",
    playedOn: "Fri Oct 02 2020 16:27:40 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Aug 05 2022 22:12:49 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour848",
    sourceHandId: "srch4130",
    boardCards: "10c7dJhJh9d",
    pot: 13,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 6,
    source: "src",
    playedOn: "Fri Jul 16 2021 20:42:33 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Apr 28 2014 20:07:48 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour674",
    sourceHandId: "srch4050",
    boardCards: "10s7h2c10s6h",
    pot: 14,
    effectiveStack: 11,
    position: "OOP",
  },
  {
    id: 7,
    source: "src",
    playedOn: "Mon May 10 2021 01:38:21 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Nov 05 2021 22:22:37 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour765",
    sourceHandId: "srch2803",
    boardCards: "8c6h4c6d8c",
    pot: 14,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 8,
    source: "src",
    playedOn: "Sun Aug 01 2021 08:54:47 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Jun 27 2020 01:40:26 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour607",
    sourceHandId: "srch3416",
    boardCards: "8h9s3h5dJs",
    pot: 13,
    effectiveStack: 21,
    position: "OOP",
  },
  {
    id: 9,
    source: "src",
    playedOn: "Mon May 23 2022 04:54:09 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Jul 26 2014 15:53:25 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour86",
    sourceHandId: "srch1951",
    boardCards: "3s10sJcQs3d",
    pot: 19,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 10,
    source: "src",
    playedOn: "Fri Jul 29 2016 23:20:40 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun May 15 2016 12:31:14 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour192",
    sourceHandId: "srch3125",
    boardCards: "Js9s10hQc4c",
    pot: 18,
    effectiveStack: 11,
    position: "OOP",
  },
  {
    id: 11,
    source: "src",
    playedOn: "Mon Nov 10 2014 23:14:10 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Sat Jul 06 2019 11:53:32 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour904",
    sourceHandId: "srch1716",
    boardCards: "4s6cAs6c4s",
    pot: 13,
    effectiveStack: 12,
    position: "OOP",
  },
  {
    id: 12,
    source: "src",
    playedOn: "Fri Jul 29 2022 03:01:22 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Nov 11 2021 20:40:31 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour759",
    sourceHandId: "srch1187",
    boardCards: "Jc2d10c10s6h",
    pot: 17,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 13,
    source: "src",
    playedOn: "Wed Mar 09 2016 13:36:49 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Sat Jun 16 2018 12:35:41 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour510",
    sourceHandId: "srch1737",
    boardCards: "5h10hQs3h2c",
    pot: 16,
    effectiveStack: 27,
    position: "OOP",
  },
  {
    id: 14,
    source: "src",
    playedOn: "Sat Feb 14 2015 21:04:54 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Wed Jul 13 2022 09:18:00 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour842",
    sourceHandId: "srch2843",
    boardCards: "5h5s4c4s9d",
    pot: 14,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 15,
    source: "src",
    playedOn: "Thu Apr 23 2020 09:47:34 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Nov 27 2021 00:35:54 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour55",
    sourceHandId: "srch1144",
    boardCards: "9d7hJd6hJd",
    pot: 11,
    effectiveStack: 20,
    position: "OOP",
  },
  {
    id: 16,
    source: "src",
    playedOn: "Thu Jul 27 2017 06:12:54 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Oct 23 2021 01:11:42 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour276",
    sourceHandId: "srch2326",
    boardCards: "8c3cQd7d6d",
    pot: 18,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 17,
    source: "src",
    playedOn: "Sat Feb 11 2017 17:08:21 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Feb 17 2021 02:15:11 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour540",
    sourceHandId: "srch1080",
    boardCards: "7h9h9h10s9d",
    pot: 19,
    effectiveStack: 29,
    position: "OOP",
  },
  {
    id: 18,
    source: "src",
    playedOn: "Fri Apr 12 2019 10:04:43 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Jul 21 2020 21:02:10 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour78",
    sourceHandId: "srch1839",
    boardCards: "7c5hQhQs3c",
    pot: 14,
    effectiveStack: 19,
    position: "OOP",
  },
  {
    id: 19,
    source: "src",
    playedOn: "Wed Nov 11 2015 01:29:31 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Tue Jan 13 2015 07:37:33 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour315",
    sourceHandId: "srch1433",
    boardCards: "4d4d3h6h9d",
    pot: 11,
    effectiveStack: 28,
    position: "OOP",
  },
  {
    id: 20,
    source: "src",
    playedOn: "Tue Feb 25 2020 11:31:28 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Dec 22 2020 17:35:52 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour56",
    sourceHandId: "srch1952",
    boardCards: "7dKcJs4cAc",
    pot: 18,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 21,
    source: "src",
    playedOn: "Fri Nov 12 2021 22:25:45 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Jul 03 2015 21:02:00 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour300",
    sourceHandId: "srch3801",
    boardCards: "7d4h9cAh6h",
    pot: 14,
    effectiveStack: 17,
    position: "OOP",
  },
  {
    id: 22,
    source: "src",
    playedOn: "Tue Apr 28 2020 06:38:09 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Nov 09 2020 19:08:10 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour734",
    sourceHandId: "srch2214",
    boardCards: "10d5s3c4dAc",
    pot: 16,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 23,
    source: "src",
    playedOn: "Sat Apr 04 2020 11:02:23 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Dec 25 2017 18:53:15 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour544",
    sourceHandId: "srch1826",
    boardCards: "8sAc5hKhKh",
    pot: 12,
    effectiveStack: 28,
    position: "OOP",
  },
  {
    id: 24,
    source: "src",
    playedOn: "Thu Sep 24 2015 00:09:32 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Tue Sep 02 2014 17:15:19 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour116",
    sourceHandId: "srch2401",
    boardCards: "10h9cJh7d4c",
    pot: 16,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 25,
    source: "src",
    playedOn: "Fri Jun 10 2022 23:32:56 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Mar 15 2016 02:30:06 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour875",
    sourceHandId: "srch3560",
    boardCards: "AcQs4c3h2c",
    pot: 17,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 26,
    source: "src",
    playedOn: "Sat Jun 13 2015 16:05:22 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Thu Aug 26 2021 13:51:21 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour159",
    sourceHandId: "srch2998",
    boardCards: "5sQd2h5d9c",
    pot: 16,
    effectiveStack: 12,
    position: "OOP",
  },
  {
    id: 27,
    source: "src",
    playedOn: "Mon May 08 2017 05:04:39 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Jan 04 2017 10:04:46 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour744",
    sourceHandId: "srch3912",
    boardCards: "5d8d10dJh8s",
    pot: 13,
    effectiveStack: 19,
    position: "OOP",
  },
  {
    id: 28,
    source: "src",
    playedOn: "Sun Oct 02 2016 04:51:09 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri May 02 2014 18:46:59 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour895",
    sourceHandId: "srch1932",
    boardCards: "4sKs5d3hQs",
    pot: 18,
    effectiveStack: 18,
    position: "OOP",
  },
  {
    id: 29,
    source: "src",
    playedOn: "Thu Apr 09 2020 23:59:09 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue May 24 2016 05:10:25 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour188",
    sourceHandId: "srch2130",
    boardCards: "8dKh2d7cJs",
    pot: 18,
    effectiveStack: 19,
    position: "OOP",
  },
  {
    id: 30,
    source: "src",
    playedOn: "Sun Sep 24 2017 10:20:18 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Aug 17 2014 00:25:34 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour625",
    sourceHandId: "srch1316",
    boardCards: "9d2d4c5c10s",
    pot: 12,
    effectiveStack: 26,
    position: "OOP",
  },
  {
    id: 31,
    source: "src",
    playedOn: "Sat Jul 04 2015 05:09:43 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Fri Sep 08 2017 21:09:49 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour112",
    sourceHandId: "srch4068",
    boardCards: "9cAh8hJs7h",
    pot: 13,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 32,
    source: "src",
    playedOn: "Thu Jul 02 2020 00:06:19 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Dec 12 2018 05:19:12 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour695",
    sourceHandId: "srch3034",
    boardCards: "3dKd3h9d10c",
    pot: 18,
    effectiveStack: 17,
    position: "OOP",
  },
  {
    id: 33,
    source: "src",
    playedOn: "Sun Aug 26 2018 16:01:31 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Apr 19 2016 15:51:17 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour655",
    sourceHandId: "srch3447",
    boardCards: "7s9cQsKdJh",
    pot: 19,
    effectiveStack: 20,
    position: "OOP",
  },
  {
    id: 34,
    source: "src",
    playedOn: "Sat Oct 31 2020 20:32:53 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Mar 09 2014 16:48:36 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour913",
    sourceHandId: "srch1707",
    boardCards: "4hKs7dQcJc",
    pot: 18,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 35,
    source: "src",
    playedOn: "Sun Aug 14 2016 21:28:41 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Mar 03 2019 21:25:55 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour954",
    sourceHandId: "srch4905",
    boardCards: "5c3d2cAsJs",
    pot: 18,
    effectiveStack: 11,
    position: "OOP",
  },
  {
    id: 36,
    source: "src",
    playedOn: "Mon Jan 06 2020 05:26:44 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Oct 06 2021 12:46:30 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour512",
    sourceHandId: "srch3386",
    boardCards: "Ad2h10h5s3s",
    pot: 15,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 37,
    source: "src",
    playedOn: "Mon Nov 30 2015 06:07:53 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Tue Jun 09 2015 07:57:46 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour716",
    sourceHandId: "srch1612",
    boardCards: "Ad9s7h5cAh",
    pot: 17,
    effectiveStack: 12,
    position: "OOP",
  },
  {
    id: 38,
    source: "src",
    playedOn: "Fri Sep 29 2017 01:41:31 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Apr 21 2021 23:22:29 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour312",
    sourceHandId: "srch4316",
    boardCards: "7dKs7sJc5d",
    pot: 17,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 39,
    source: "src",
    playedOn: "Thu Jan 14 2021 20:27:03 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Jul 06 2017 06:06:18 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour96",
    sourceHandId: "srch3066",
    boardCards: "Jd5dKcAcJh",
    pot: 17,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 40,
    source: "src",
    playedOn: "Fri Mar 29 2019 06:09:33 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Jan 03 2022 21:06:00 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour767",
    sourceHandId: "srch1486",
    boardCards: "5d9s6h4d10d",
    pot: 17,
    effectiveStack: 15,
    position: "OOP",
  },
  {
    id: 41,
    source: "src",
    playedOn: "Wed Aug 10 2016 02:21:32 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Jun 07 2016 00:39:35 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour531",
    sourceHandId: "srch4314",
    boardCards: "Js3h10c6c3c",
    pot: 12,
    effectiveStack: 21,
    position: "OOP",
  },
  {
    id: 42,
    source: "src",
    playedOn: "Sun Aug 29 2021 02:14:29 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Apr 29 2022 13:38:35 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour554",
    sourceHandId: "srch4491",
    boardCards: "4c8sJs8c4d",
    pot: 11,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 43,
    source: "src",
    playedOn: "Wed Aug 05 2015 00:32:13 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Wed Mar 20 2019 16:43:36 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour822",
    sourceHandId: "srch1422",
    boardCards: "3sJdJsKcJd",
    pot: 15,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 44,
    source: "src",
    playedOn: "Wed Feb 12 2014 19:18:15 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Oct 28 2018 04:12:00 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour534",
    sourceHandId: "srch2112",
    boardCards: "9sKd10h10d9h",
    pot: 14,
    effectiveStack: 16,
    position: "OOP",
  },
  {
    id: 45,
    source: "src",
    playedOn: "Wed Feb 10 2021 03:37:51 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Aug 08 2022 11:56:53 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour152",
    sourceHandId: "srch2790",
    boardCards: "9c10s2dQcQh",
    pot: 14,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 46,
    source: "src",
    playedOn: "Sun Nov 17 2019 02:19:06 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Apr 30 2018 08:51:10 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour470",
    sourceHandId: "srch4859",
    boardCards: "5d9c4s8h7c",
    pot: 19,
    effectiveStack: 27,
    position: "OOP",
  },
  {
    id: 47,
    source: "src",
    playedOn: "Tue Sep 09 2014 16:36:22 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Feb 06 2017 01:44:48 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour537",
    sourceHandId: "srch3764",
    boardCards: "5d4sAsJs6h",
    pot: 15,
    effectiveStack: 18,
    position: "OOP",
  },
  {
    id: 48,
    source: "src",
    playedOn: "Tue Sep 06 2016 21:06:12 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Jan 02 2021 20:19:56 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour195",
    sourceHandId: "srch3602",
    boardCards: "Kd6d8d2s6h",
    pot: 14,
    effectiveStack: 18,
    position: "OOP",
  },
  {
    id: 49,
    source: "src",
    playedOn: "Fri Oct 11 2019 19:02:34 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Apr 13 2018 18:20:17 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour560",
    sourceHandId: "srch3770",
    boardCards: "10d4cAdKs5s",
    pot: 15,
    effectiveStack: 13,
    position: "OOP",
  },
  {
    id: 50,
    source: "src",
    playedOn: "Sun Mar 12 2017 16:07:59 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Aug 09 2017 04:56:36 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour610",
    sourceHandId: "srch1325",
    boardCards: "6h3d5s3c5s",
    pot: 15,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 51,
    source: "src",
    playedOn: "Sun Jan 19 2020 00:51:43 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Sep 20 2017 09:43:22 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour674",
    sourceHandId: "srch2745",
    boardCards: "Kh3c9h3hAh",
    pot: 12,
    effectiveStack: 13,
    position: "OOP",
  },
  {
    id: 52,
    source: "src",
    playedOn: "Sun Nov 09 2014 22:20:43 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Sat Jul 14 2018 05:02:02 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour141",
    sourceHandId: "srch2764",
    boardCards: "6s5c5h6d7c",
    pot: 11,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 53,
    source: "src",
    playedOn: "Fri Dec 13 2019 02:21:34 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Aug 15 2020 12:27:41 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour336",
    sourceHandId: "srch3693",
    boardCards: "6hQs8s7s4s",
    pot: 15,
    effectiveStack: 13,
    position: "OOP",
  },
  {
    id: 54,
    source: "src",
    playedOn: "Thu Jan 31 2019 08:39:10 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Jun 09 2019 10:25:23 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour468",
    sourceHandId: "srch3567",
    boardCards: "6d4h6sAh9h",
    pot: 12,
    effectiveStack: 18,
    position: "OOP",
  },
  {
    id: 55,
    source: "src",
    playedOn: "Tue Jul 07 2020 02:22:19 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Dec 23 2021 13:47:24 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour391",
    sourceHandId: "srch3198",
    boardCards: "Jd9c8d4hKc",
    pot: 15,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 56,
    source: "src",
    playedOn: "Fri Jun 06 2014 10:57:01 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Oct 29 2017 16:55:49 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour234",
    sourceHandId: "srch4685",
    boardCards: "AdJd6sQh5s",
    pot: 19,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 57,
    source: "src",
    playedOn: "Wed Sep 07 2016 03:41:09 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Nov 22 2016 12:22:59 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour17",
    sourceHandId: "srch1987",
    boardCards: "Ah5h2c9h6d",
    pot: 14,
    effectiveStack: 20,
    position: "OOP",
  },
  {
    id: 58,
    source: "src",
    playedOn: "Tue Jun 07 2022 11:40:54 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat May 18 2019 13:22:55 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour275",
    sourceHandId: "srch4309",
    boardCards: "AhKd4d4s8c",
    pot: 14,
    effectiveStack: 26,
    position: "OOP",
  },
  {
    id: 59,
    source: "src",
    playedOn: "Thu Aug 13 2015 15:45:34 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Fri Jul 17 2015 21:57:59 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour231",
    sourceHandId: "srch2213",
    boardCards: "8dKd7s9h7h",
    pot: 12,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 60,
    source: "src",
    playedOn: "Sat Jul 13 2019 00:51:59 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Sep 24 2018 18:42:53 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour52",
    sourceHandId: "srch4328",
    boardCards: "10s8hJc7h7c",
    pot: 19,
    effectiveStack: 28,
    position: "OOP",
  },
  {
    id: 61,
    source: "src",
    playedOn: "Thu Jun 09 2016 08:29:29 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Mon Jun 27 2022 14:06:22 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour508",
    sourceHandId: "srch3681",
    boardCards: "AhJs3h6s2h",
    pot: 17,
    effectiveStack: 21,
    position: "OOP",
  },
  {
    id: 62,
    source: "src",
    playedOn: "Tue Apr 02 2019 03:28:30 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Jan 11 2022 17:25:28 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour883",
    sourceHandId: "srch2494",
    boardCards: "Jd10cAs2s9s",
    pot: 12,
    effectiveStack: 13,
    position: "OOP",
  },
  {
    id: 63,
    source: "src",
    playedOn: "Fri Aug 12 2016 13:24:08 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Aug 16 2021 15:25:46 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour710",
    sourceHandId: "srch3769",
    boardCards: "Kd4h9h8h10s",
    pot: 12,
    effectiveStack: 21,
    position: "OOP",
  },
  {
    id: 64,
    source: "src",
    playedOn: "Wed Oct 08 2014 01:38:23 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Oct 13 2014 21:41:37 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour733",
    sourceHandId: "srch3059",
    boardCards: "8dKc3dKh9s",
    pot: 12,
    effectiveStack: 29,
    position: "OOP",
  },
  {
    id: 65,
    source: "src",
    playedOn: "Fri Jan 26 2018 17:18:10 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Apr 27 2018 09:57:25 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour969",
    sourceHandId: "srch3316",
    boardCards: "AcAh2dKh3h",
    pot: 19,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 66,
    source: "src",
    playedOn: "Fri May 27 2022 01:09:16 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Feb 17 2014 23:20:52 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour923",
    sourceHandId: "srch3505",
    boardCards: "4c9d6h10s4d",
    pot: 19,
    effectiveStack: 21,
    position: "OOP",
  },
  {
    id: 67,
    source: "src",
    playedOn: "Thu Dec 02 2021 18:09:41 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sun Apr 26 2015 12:56:44 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour671",
    sourceHandId: "srch3085",
    boardCards: "7cAs9cQh2s",
    pot: 12,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 68,
    source: "src",
    playedOn: "Sun Mar 20 2016 14:21:13 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Thu Apr 24 2014 20:19:00 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour164",
    sourceHandId: "srch2525",
    boardCards: "Js6c4c4s7d",
    pot: 12,
    effectiveStack: 11,
    position: "OOP",
  },
  {
    id: 69,
    source: "src",
    playedOn: "Sat May 28 2022 06:04:58 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Aug 11 2021 06:00:55 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour122",
    sourceHandId: "srch3913",
    boardCards: "3sJh10sQs10s",
    pot: 19,
    effectiveStack: 13,
    position: "OOP",
  },
  {
    id: 70,
    source: "src",
    playedOn: "Sun May 08 2016 12:38:21 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Sat Sep 19 2020 05:48:15 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour772",
    sourceHandId: "srch2226",
    boardCards: "8h8hQhKc3h",
    pot: 16,
    effectiveStack: 16,
    position: "OOP",
  },
  {
    id: 71,
    source: "src",
    playedOn: "Mon Sep 02 2019 11:06:02 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Nov 20 2020 13:37:48 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour696",
    sourceHandId: "srch4157",
    boardCards: "4h4c8dJc4d",
    pot: 12,
    effectiveStack: 15,
    position: "OOP",
  },
  {
    id: 72,
    source: "src",
    playedOn: "Tue Sep 21 2021 10:43:21 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Apr 29 2017 03:46:45 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour940",
    sourceHandId: "srch1354",
    boardCards: "3cAs10s8h9c",
    pot: 12,
    effectiveStack: 17,
    position: "OOP",
  },
  {
    id: 73,
    source: "src",
    playedOn: "Tue Jan 18 2022 03:26:38 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Jul 08 2016 06:07:32 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour500",
    sourceHandId: "srch4639",
    boardCards: "9hJd10c10cAs",
    pot: 13,
    effectiveStack: 15,
    position: "OOP",
  },
  {
    id: 74,
    source: "src",
    playedOn: "Sun Apr 07 2019 18:08:20 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Jun 10 2017 03:07:27 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour275",
    sourceHandId: "srch1391",
    boardCards: "6cQh6sQh4c",
    pot: 11,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 75,
    source: "src",
    playedOn: "Tue Jan 08 2019 21:30:39 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Mar 31 2018 13:05:14 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour32",
    sourceHandId: "srch3645",
    boardCards: "4c8dJcAcJh",
    pot: 17,
    effectiveStack: 29,
    position: "OOP",
  },
  {
    id: 76,
    source: "src",
    playedOn: "Mon Apr 21 2014 14:22:31 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Feb 06 2018 22:34:35 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour161",
    sourceHandId: "srch3247",
    boardCards: "QhKc7c4s5d",
    pot: 16,
    effectiveStack: 19,
    position: "OOP",
  },
  {
    id: 77,
    source: "src",
    playedOn: "Thu Mar 01 2018 01:05:00 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Sep 19 2016 22:20:35 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour53",
    sourceHandId: "srch2154",
    boardCards: "9s10s3d8c5d",
    pot: 12,
    effectiveStack: 18,
    position: "OOP",
  },
  {
    id: 78,
    source: "src",
    playedOn: "Tue Jul 24 2018 09:42:00 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Jan 03 2020 16:33:17 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour934",
    sourceHandId: "srch3091",
    boardCards: "7c8d3s8cKc",
    pot: 15,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 79,
    source: "src",
    playedOn: "Fri Aug 29 2014 04:34:43 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Jul 17 2021 19:57:40 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour361",
    sourceHandId: "srch1659",
    boardCards: "9s7d10dJs5s",
    pot: 17,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 80,
    source: "src",
    playedOn: "Sun Dec 17 2017 23:07:36 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Jul 11 2019 02:24:48 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour195",
    sourceHandId: "srch3089",
    boardCards: "KcJs6dKd3h",
    pot: 19,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 81,
    source: "src",
    playedOn: "Thu Mar 26 2015 10:19:13 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Tue Nov 12 2019 00:59:05 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour927",
    sourceHandId: "srch1901",
    boardCards: "9c6d5sAs10c",
    pot: 18,
    effectiveStack: 28,
    position: "OOP",
  },
  {
    id: 82,
    source: "src",
    playedOn: "Wed Mar 13 2019 15:13:14 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Mon Sep 04 2017 16:57:48 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour741",
    sourceHandId: "srch1088",
    boardCards: "9c4d3s5c10d",
    pot: 12,
    effectiveStack: 23,
    position: "OOP",
  },
  {
    id: 83,
    source: "src",
    playedOn: "Thu Feb 13 2014 09:01:44 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Jan 05 2019 20:39:43 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour440",
    sourceHandId: "srch4369",
    boardCards: "2d4d9c8h5c",
    pot: 14,
    effectiveStack: 20,
    position: "OOP",
  },
  {
    id: 84,
    source: "src",
    playedOn: "Tue Apr 10 2018 21:27:23 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Jul 15 2015 09:16:50 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour716",
    sourceHandId: "srch2208",
    boardCards: "5d9s9s7c5h",
    pot: 12,
    effectiveStack: 12,
    position: "OOP",
  },
  {
    id: 85,
    source: "src",
    playedOn: "Sat Jun 13 2015 17:04:48 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Mon Jan 10 2022 00:18:21 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour942",
    sourceHandId: "srch3956",
    boardCards: "8c5d3d10c7h",
    pot: 12,
    effectiveStack: 11,
    position: "OOP",
  },
  {
    id: 86,
    source: "src",
    playedOn: "Thu May 04 2017 06:55:29 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Jul 01 2022 04:57:42 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour218",
    sourceHandId: "srch4242",
    boardCards: "KhQs10cQs9s",
    pot: 14,
    effectiveStack: 15,
    position: "OOP",
  },
  {
    id: 87,
    source: "src",
    playedOn: "Sun Feb 02 2020 01:42:40 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Jan 22 2014 12:30:08 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour623",
    sourceHandId: "srch4031",
    boardCards: "8h5s5hJc6c",
    pot: 19,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 88,
    source: "src",
    playedOn: "Sat May 05 2018 17:16:16 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Wed Jan 29 2014 04:08:07 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour347",
    sourceHandId: "srch3303",
    boardCards: "9hAh2d2c6h",
    pot: 15,
    effectiveStack: 27,
    position: "OOP",
  },
  {
    id: 89,
    source: "src",
    playedOn: "Wed Dec 20 2017 04:19:51 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Aug 22 2015 00:06:12 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour274",
    sourceHandId: "srch4552",
    boardCards: "8c7s8h2d3h",
    pot: 17,
    effectiveStack: 24,
    position: "OOP",
  },
  {
    id: 90,
    source: "src",
    playedOn: "Sat May 30 2015 14:57:21 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Fri Jan 05 2018 18:26:20 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour56",
    sourceHandId: "srch3719",
    boardCards: "4d4c6d10dQc",
    pot: 15,
    effectiveStack: 27,
    position: "OOP",
  },
  {
    id: 91,
    source: "src",
    playedOn: "Fri Sep 09 2016 10:05:27 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Feb 27 2014 04:04:35 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour183",
    sourceHandId: "srch4674",
    boardCards: "8h3h2s9dQc",
    pot: 15,
    effectiveStack: 15,
    position: "OOP",
  },
  {
    id: 92,
    source: "src",
    playedOn: "Tue Jul 19 2016 17:21:31 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Fri Jan 23 2015 07:03:27 GMT+0600 (Novosibirsk Standard Time)",
    tournamentId: "tour959",
    sourceHandId: "srch3548",
    boardCards: "8s7h2h4h4s",
    pot: 18,
    effectiveStack: 14,
    position: "OOP",
  },
  {
    id: 93,
    source: "src",
    playedOn: "Fri Nov 05 2021 17:54:55 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Dec 27 2019 00:28:15 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour568",
    sourceHandId: "srch1498",
    boardCards: "Ks8h2sJcKh",
    pot: 18,
    effectiveStack: 20,
    position: "OOP",
  },
  {
    id: 94,
    source: "src",
    playedOn: "Sun May 08 2022 20:12:09 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Fri Nov 02 2018 03:11:24 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour692",
    sourceHandId: "srch1863",
    boardCards: "5c7dAd9cKd",
    pot: 19,
    effectiveStack: 19,
    position: "OOP",
  },
  {
    id: 95,
    source: "src",
    playedOn: "Fri Jun 19 2015 04:48:57 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Tue Nov 09 2021 08:25:59 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour351",
    sourceHandId: "srch3100",
    boardCards: "9s2d7h7d9s",
    pot: 18,
    effectiveStack: 29,
    position: "OOP",
  },
  {
    id: 96,
    source: "src",
    playedOn: "Sun Jul 05 2015 02:09:13 GMT+0600 (Novosibirsk Standard Time)",
    importedOn: "Sun Aug 03 2014 00:24:04 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour186",
    sourceHandId: "srch3023",
    boardCards: "5s5dJdKdQc",
    pot: 18,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 97,
    source: "src",
    playedOn: "Wed Oct 05 2016 01:07:29 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Thu Jun 24 2021 19:40:00 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour889",
    sourceHandId: "srch2938",
    boardCards: "4c5d10c2s2c",
    pot: 14,
    effectiveStack: 25,
    position: "OOP",
  },
  {
    id: 98,
    source: "src",
    playedOn: "Wed Dec 25 2019 20:04:53 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Tue Dec 10 2019 08:14:07 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour976",
    sourceHandId: "srch2043",
    boardCards: "Kd6c9s8c3s",
    pot: 13,
    effectiveStack: 22,
    position: "OOP",
  },
  {
    id: 99,
    source: "src",
    playedOn: "Mon Mar 30 2020 15:58:15 GMT+0700 (Novosibirsk Standard Time)",
    importedOn: "Sat Aug 03 2019 18:00:55 GMT+0700 (Novosibirsk Standard Time)",
    tournamentId: "tour188",
    sourceHandId: "srch2255",
    boardCards: "Jd5sKhKs5h",
    pot: 12,
    effectiveStack: 12,
    position: "OOP",
  },
]
export { TEST_DATA }
