import { Grid } from "@mui/material"
import Container from "@mui/material/Container"
import Typography from "@mui/material/Typography"
import { useAuthUser } from "./hooks"

function Profile() {
  const profile = useAuthUser()

  if (!profile) return <></>

  return (
    <Container>
      <Grid container spacing={2} mt={1}>
        <Grid item xs={12}>
          <Typography variant="h4">Profile</Typography>
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Avatar</Typography>
        </Grid>
        <Grid item xs={10}>
          <img src={profile.imageUrl} />
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Email</Typography>
        </Grid>
        <Grid item xs={10}>
          {profile.email}
        </Grid>

        <Grid item xs={2}>
          <Typography variant="subtitle1">Name</Typography>
        </Grid>
        <Grid item xs={10}>
          {profile.name}
        </Grid>
      </Grid>
    </Container>
  )
}

export default Profile
