import ChevronRightIcon from "@mui/icons-material/ChevronRight"
import ClearIcon from "@mui/icons-material/Clear"
import ExpandMoreIcon from "@mui/icons-material/ExpandMore"
import TreeItem from "@mui/lab/TreeItem"
import TreeView from "@mui/lab/TreeView"
import { IconButton, Typography } from "@mui/material"
import Box from "@mui/material/Box"
import Button from "@mui/material/Button"
import Dialog from "@mui/material/Dialog"
import DialogActions from "@mui/material/DialogActions"
import DialogContent from "@mui/material/DialogContent"
import DialogTitle from "@mui/material/DialogTitle"
import * as React from "react"
import { useEffect, useMemo, useState } from "react"
import { useDeleteStackSizeMutation } from "services/rangesApi"
import { RangeSet } from "services/types"
import { ActionKind } from "./Ranges"
import { HERO_POSITIONS, Position, POSITIONS, RangeParameters } from "./types"

export interface RangeParametersEditProps {
  handleChange: (field: ActionKind, value: string | Position) => void
  state: RangeParameters
  rangeset: RangeSet
}
export function RangeParametersEdit(props: RangeParametersEditProps) {
  const { state, handleChange, rangeset } = props
  const [expanded, setExpanded] = useState("")
  const [stackToDelete, showDeleteDialog] = useState<string | undefined>()
  const [removeSize, { data, isLoading, error }] = useDeleteStackSizeMutation()

  const stackSizes = useMemo(() => {
    const n_sizes = rangeset.sizes.map((v) => v.size).sort((a, b) => a - b)
    return n_sizes.map((v) => v.toString())
  }, [rangeset.sizes])

  const positions = useMemo(() => {
    if (state.stackSize === undefined) return []
    const sizeInfo = rangeset.sizes.filter(
      (v) => v.size.toString() == state.stackSize
    )[0]
    if (sizeInfo === undefined) return []
    return HERO_POSITIONS.map((v) => {
      const disabled =
        sizeInfo.available_position.indexOf(v.position) < 0 ||
        v.position === "BB"
      v.enabled = !disabled
      return v
    })
  }, [state, rangeset.sizes])

  useEffect(() => {
    if (
      state.stackSize === undefined &&
      stackSizes !== undefined &&
      stackSizes.length > 0
    )
      handleChange("stackSize", stackSizes[0])
    if (
      state.heroPosition === undefined &&
      positions !== undefined &&
      positions.length > 0
    )
      handleChange("heroPosition", positions[0].position)
  }, [state, stackSizes, positions, handleChange])

  const handleDeleteStackSize = async (
    event: React.MouseEvent<HTMLElement>,
    size: string
  ) => {
    event.preventDefault()
    console.log(`Delete ${size}`)
    showDeleteDialog(size)
  }

  const closeDialog = () => {
    stackToDelete && showDeleteDialog(undefined)
  }

  const handleOk = () => {
    // API
    if (!stackToDelete) return
    console.log(`Deleting ${stackToDelete}`)
    removeSize({ rangeset: rangeset.id, size: stackToDelete })
    closeDialog()
  }

  const tree = useMemo(() => {
    const positionItem = (position: string) => <Box>{position}</Box>
    const stackSizeItem = (size: string) => (
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <Box
          sx={{ flexGrow: 1 }}
          onClick={() => {
            const show = size !== expanded
            setExpanded(show ? size : "")
          }}
        >
          {size}
        </Box>
        <Box>
          <IconButton onClick={(e) => handleDeleteStackSize(e, size)}>
            <ClearIcon />
          </IconButton>
        </Box>
      </Box>
    )
    if (state.rangeset === undefined) return null
    const stacks = [...rangeset.sizes].sort((a, b) => a.size - b.size)

    return stacks.map((stackSize) => {
      return (
        <TreeItem
          key={stackSize.size}
          nodeId={stackSize.size.toString()}
          label={stackSizeItem(stackSize.size.toString())}
        >
          {[...stackSize.available_position]
            .sort((a, b) => POSITIONS.indexOf(a) - POSITIONS.indexOf(b))
            .map((position) => (
              <TreeItem
                key={stackSize.size + "_" + position}
                nodeId={stackSize.size + "_" + position}
                label={positionItem(position)}
                disabled={position == "BB"}
                onClick={() => {
                  if (position == "BB") return
                  handleChange("stackSize", stackSize.size.toString())
                  handleChange("heroPosition", position)
                }}
              ></TreeItem>
            ))}
        </TreeItem>
      )
    })
  }, [state, rangeset.sizes, handleChange, expanded])

  const navigation = () => (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      expanded={[state.stackSize + "", expanded]}
      selected={[state.stackSize + "_" + state.heroPosition]}
      sx={{
        flexGrow: 1,
        maxWidth: 400,
        minWidth: 400,
        overflowY: "auto",
        background: "#CCCCCC",
        borderRadius: "8px",
        p: 1,
      }}
    >
      {tree}
    </TreeView>
  )

  return (
    <Box
      sx={{
        ml: { xs: 0, md: 2 },
        mt: { xs: 2, md: 0 },
        p: 2,
        borderRadius: "12px",
        border: "1px solid #D3D3D3",
        backgroundColor: "#EEEEEE",
      }}
    >
      <Box>
        <Box sx={{ pb: 1 }}>Stack size in BB {"->"} Position</Box>
        {state.rangeset !== undefined && navigation()}
      </Box>
      <Dialog open={stackToDelete !== undefined} onClose={closeDialog}>
        <DialogTitle>Delete stack size?</DialogTitle>
        <DialogContent>
          <Typography>Are you sure want to delete stack size?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeDialog}>Cancel</Button>
          <Button onClick={handleOk}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
