import Box from "@mui/material/Box"
import CssBaseline from "@mui/material/CssBaseline"
import { ThemeProvider } from "@mui/material/styles"
import Profile from "features/auth/Profile"
import { HandHistories } from "features/HandHistories/HandHistories"
import { Ranges } from "features/Ranges/Ranges"
import { Simulations } from "features/Simulations/Simulations"
import { useTheme } from "lib/useTheme"
import { useEffect } from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useProfileQuery } from "services/userApi"
import "./App.css"
import { LeftNavigation } from "./LeftNavigation"
import { TopBar } from "./TopBar"

const NotFound = () => {
  return <>Page not found</>
}
function App() {
  const theme = useTheme()

  const {
    isFetching: userIsFetching,
    data: user,
    error: userError,
  } = useProfileQuery()
  const navigate = useNavigate()

  useEffect(() => {
    if (
      (!userIsFetching && !user) ||
      (userError && "status" in userError && userError.status === 403)
    ) {
      navigate("/signin/")
    }
  })

  if (!user) return null

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column" }}>
        <TopBar user={user} />

        <Box sx={{ flexGrow: 1, display: "flex" }}>
          <LeftNavigation />

          <Box
            component="main"
            sx={{ flexGrow: 1, p: { xs: 0, sm: 3 }, display: "flex" }}
          >
            <Routes>
              <Route path="/" element={<Navigate to="/ranges" replace />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/hand-histories" element={<HandHistories />} />
              <Route path="/simulations" element={<Simulations />} />
              <Route path="/ranges" element={<Ranges />} />
              <Route path="*" element={<NotFound />} />
            </Routes>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  )
}

export default App
